import translations from "./translations";

export default function translate(key, parameters) {
  let translationItem = translations[key];
  if (!translationItem) {
    return `NOT FOUND - ${key}`;
  }
  if (!translationItem[window.language]) {
    return `NOT FOUND (${window.language})- ${key}`;
  }

  return translationItem[window.language];
}
