import React from "react";
import Code from "../../Code/Code";

export const code1 = (
  <Code>{`var a:string = "hello world";
let b:number = 5;
let c:string[] = ["a", "b", "c"]; // array of strings
const d:object = {hello: "world"};
var e:boolean = true;`}</Code>
);

export const code2 = (
  <Code>{`var letters:string[] = ["a", "b", "c"];
var first = letters[0];
var second = letters[1];`}</Code>
);

export const code3 = (
  <Code>
    {`type Person = {
  name: string;
  age: number;
  nationality: string;
};

type ArrayOfStrings = string[];

var you:Person = {
  name: 'Chris',
  age: 20,
  nationality: 'Martian'
};

var letters:ArrayOfStrings = ["a", "b", "c"];`}
  </Code>
);

export const code4 = (
  <Code>{`var you:{
  name: string;
  age: number;
  nationality: string;
} = {
  name: 'Chris',
  age: 20,
  nationality: 'Martian'
};

var letters:string[] = ["a", "b", "c"];
`}</Code>
);
