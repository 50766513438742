import React from "react";
import { Divider, Typography, Row, Col } from "antd";
import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
// import * as common from "./common";
import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";
import translate from "../../common/translate";

import CallStackImage from "./call_stack.png";

import "./common.scss";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      Daca in limbajul computerelor o variabila reprezinta un substantiv, atunci o functie reprezinta un verb. Functiile
      ne permit sa scriem cod reutilizabil, modular si mult mai usor de citit.
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <div className="lesson-functions">
      <LESSON_RO_ABSTRACT />
      <p>
        Ce s-ar intampla daca am incerca sa programam un computer astfel incat sa faca o actiune complexa din lumea
        reala? De exemplu, sa gateasca o prajitura. Pentru a aborda o astfel de cerinta, trebuie sa o descompunem in
        actiuni simple, modulare. In exemplul nostru, aceste actiuni ar putea fi:
      </p>
      <ul>
        <Row>
          <Col xl={8} sm={12} xs={24}>
            <li>spart oua</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>batut albus</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>masurat zahar</li>
          </Col>

          <Col xl={8} sm={12} xs={24}>
            <li>masurat faina</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>masurat lapte</li>
          </Col>

          <Col xl={8} sm={12} xs={24}>
            <li>adaugat faina intr-un bol</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>adaugat lapte intr-un bol</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>adaugat zahar intr-un bol</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>amestecat ingrediente</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>incalzit cuptor</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>turnat ingrediente in tava</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>pus tava in cuptor</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>verificat starea prajiturii</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>scos prajitura din cuptor</li>
          </Col>
          <Col xl={8} sm={12} xs={24}>
            <li>taiat prajitura in portii</li>
          </Col>
        </Row>
      </ul>
      <p>
        Par multi pasi, si pe de o parte sunt. Dar atunci cand noi, ca oameni, incercam sa rezolvam aceasta problema, ii
        urmam fara sa ne gandim neaparat la ei. Atunci cand programam un computer, fiecare pas trebuie programat si
        detaliat. La baza, toti acesti pasi vor fi alcatuiti dintr-o combinatie de elemente studiate pana acum:
        variabile, conditii, bucle. Problema apare atunci cand vrem sa repetam anumiti pasi in mai multe momente ale
        procesului, sau atunci cand avem etape care sunt aproape identice, dar cu mici modificari.
      </p>
      <Important label="Un principiu fundamental in programare este sa evitam repetarea codului pe cat posibil. Un acronim foarte des intalnit este DRY (don't repeat yourself), si este des folosit de catre programatori pentru a explica de ce au ales o anumita solutie." />
      <br />
      <p>
        Pentru a evita repetarea codului, ne folosim de bucle (pe care deja le-am studiat) si de functii. In exemplul de
        mai sus, am putea defini cate o functie pentru fiecare dintre pasii enumerati, iar in interiorul fiecareia, sa
        punem tot codul necesar pentru a face actiunea respectiva. De asta spunem ca functiile sunt modulare: putem
        creea cate o functie pentru fiecare modul, iar apoi le putem combina oricum avem nevoie. Ca si variabilele,
        functiile au nume, ceea ce face codul usor de citit.
      </p>
      <br />
      <br />
      <Divider>
        <Typography.Title level={2}>Partile unei functii</Typography.Title>
      </Divider>
      <CodeEditor
        stacked
        defaultCode={`function breakEggs(count:number) {
  console.log('we are breaking ' + count + " eggs")
}

breakEggs(3);
breakEggs(5);`}
      />

      <p>Declaratia unei functii este alcatuita din:</p>
      <ul>
        <li>
          cuvantul cheie <Code inline code="function" />
        </li>
        <li>numele functiei</li>
        <li>parenteze rotunde</li>
        <li>
          optional, parametri - acestia sunt declarati in interiorul parantezelor rotunde, iar daca sunt mai multi de 1,
          sunt separati cu virgula
        </li>
        <li>corpul functiei, delimitat de acolade (la fel ca la conditii si bucle)</li>
      </ul>

      <Important
        label={
          <>
            Dupa ce am declarat o functie, o putem folosi. Pentru a folosi o functie, o <b>apelam</b>. Asta inseamna ca
            ii scriem numele, urmat de paranteze rotunde. Daca functia foloseste parametri, intre paranteze vom da
            valori acelor parametri, in ordinea in care sunt declarati in functie.
          </>
        }
      />
      <br />
      <Important
        label={
          <>
            Desi in mod normal nu putem folosi o functie inainte sa fie declarata, in TypeScript beneficiem de un
            mecanism numit <Notion label="hoisting" important />, care presupune ca atunci cand programul ruleaza,
            declaratiile functiilor sunt automat mutate la inceputul fisierului. Asta inseamna ca oriunde putem apela o
            functie inainte de a o declara.
          </>
        }
      />
      <br />
      <p>
        In corpul unei functii putem avea orice alte linii de cod, inclusiv apeluri catre alte functii. In corpul
        functiei "breakEggs", apelam o alta functie, de data asta predefinita: <b>console.log</b>
      </p>

      <br />
      <br />
      <Divider>
        <Typography.Title level={2}>Parametri</Typography.Title>
      </Divider>
      <p>
        Atunci cand declaram o functie, putem specifica ce parametri foloseste. Parametrii se comporta ca niste
        variabile, singura diferenta fiind ca atunci cand declaram functia, nu stim ce valori au (le stim doar tipul),
        iar atunci cand apelam functia, le dam valori. Asta ne permite sa scriem functii care au comportament diferit in
        functie de valorile parametrilor.
      </p>
      <p>
        In functia "breakEggs" din exemplul nostru, numarul de oua care trebuie sparte este transmis prin parametrul cu
        numele "count" de tip numar. Atunci cand functia este declarata, nu stim ce valoare are, dar asta nu creeaza
        nicio problema. In cazul nostru doar vrem sa afisam mesajul in consola, iar pentru asta ne folosim de valoarea
        parametrului "count", indiferent care ar fi ea.
      </p>

      <Important label="Valorile parametrilor sunt stiute doar la momentul apelarii functiei, nu atunci cand o declaram. Asta permite functiilor sa aiba un comportament dinamic, in functie de valorile parametrilor." />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2}>Call stack si returnarea unei valori</Typography.Title>
      </Divider>

      <p>
        Stim deja ca executia unui program merge de sus in jos. Atunci cand intalnim un apel de functie, computerul isi
        muta intreaga atentie catre acea functie. Asta inseamna ca restul programului este pus pe pauza pana functia
        curenta se termina de executat. Cand functia s-a terminat de executat executia programului continua de unde
        ramasese atunci cand a intalnit apelul de functie.
      </p>
      <Important label="Intrebarea este: ce se intampla daca in timpul executiei unei functii, intalnim un alt apel de functie, iar in timpul executiei lui, intalnim altul, si tot asa? Si mai mult decat atat, nu ar fi o idee buna sa putem cere informatii unei functii, iar ea sa ni le poata da?" />
      <br />
      <p>
        Exista raspunsul la ambele intrebari este ceea ce se numeste un <Notion label="call stack" />.
      </p>
      <p>
        Orice program functioneaza pe baza unui sistem numit "call stack" (call = "apel", "stack" =
        stiva/teanc/gramada). Stiva ("stack") reprezinta o structura de date in care intotdeauna adaugam deasupra, si
        tot de deasupra luam elementele, exact ca un teanc de carti aflat pe o masa in lumea reala - nu putem lua o
        carte de la baza stivei, putem lua doar de sus.
      </p>
      <p>
        Cand incepe executia unui program, stiva este goala. Cand intalnim primul apel de functie, se adauga un element
        in stiva. Asta ii permite computerului sa isi directioneze atentia catre acea functie, fara sa uite unde a ramas
        in program. Din moment ce un program (in mod normal) poate executa o singura instructiune o data, asta inseamna
        ca restul programului este "pus pe pauza", sau temporar ignorat. Cand executia functiei curente se termina,
        elementul asociat ei din stiva este scos, iar programul continua.
      </p>
      <p>
        Daca in timpul executiei unei functii intalnim alta functie, atunci se adauga inca un element in stiva, iar
        atentia computerului este directionata catre cea mai recenta functie, si tot asa. Acest proces se repeta pana am
        ajuns la o functie care nu mai apeleaza nicio alta functie la randul ei, o executam, iar apoi ne intoarcem pana
        la nivelul principal al programului. In acel moment, stiva este din nou goala.
      </p>
      <p>
        Raspunsul la a doua intrebare este: atunci cand o functie isi termina executia iar programul se intoarce un
        nivel inapoi, poate lua cu el o valoare. Aceasta valoare poarta denumirea de <b>valoare returnata</b>.
      </p>
      <Important label="Returnarea unei valori, impreuna cu parametri dati atunci cand se apeleaza o functie, permit comunicare bidirectionala intre functii." />

      <br />
      <p>
        Ne putem inchipui urmatoarea situatie: avem un program care face o prajitura, apoi o mananca. O serie
        simplificata de pasi ar arata astfel:
      </p>
      <ol>
        <li>Initial, apelam o functie numita "makeCake" ("fa prajitura").</li>
        <li>In interiorul ei, aceasta apeleaza o alta functie, numita "breakEggs" ("sparge ouale").</li>
        <li>Functia breakEggs apeleaza la randul ei o noua functie pe nume "getEggs" ("obtine ouale").</li>
        <li>Functia getEggs obtine ouale si le returneaza catre functia "breakEggs".</li>
        <li>
          Atunci cand executia functiei breakEggs se termina, programul se intoarce la functia principala makeCake, dar
          de aceasta data nu returneaza nimic, adica returneaza <b>undefined</b>.
        </li>
        <li>
          Dupa ce s-a terminat si executia functiei makeCake, programul se intoarce la nivelul principal (returnand din
          noua valoarea <b>undefined</b>), iar stiva este acum goala.
        </li>
        <li>Programul trece la urmatoarea functie, "eatCake" ("mananca prajitura").</li>
      </ol>
      <Important
        label={
          <>
            O functie intotdeauna returneaza o valoare. Daca noi nu specificam ce valoare sa returnam dintr-o functie,
            ea va returna intotdeauna <b>undefined</b>.
          </>
        }
      />
      <br />
      <p>Pasii pe care tocmai i-am explicat ar arata astfel intr-o diagrama:</p>
      <div className="call-stack-image-container">
        <img src={CallStackImage} alt="" />
      </div>
      <br />
      <p>
        Pentru a returna o valoare, folosim cuvantul cheie <Code inline code="return" />, urmat de un spatiu si valoarea
        pe care vrem sa o returnam. Aceasta valoare poate fi de fapt o variabila sau un apel de functie. Daca este un
        apel de functie, atunci functia va fi apelata, iar valoarea ei returnata va fi folosita mai departe.
      </p>
      <p>Un exemplu foarte simplu este o functie care calculeaza suma a doua numere:</p>
      <CodeEditor
        defaultCode={`function sum(a:number, b:number) {
  return a + b;
}

console.log(sum(7,9));
console.log(sum(15,10));
console.log(sum(-1,1));`}
      />

      <p>
        In acest exemplu, am apelat de 3 ori functia "sum" ("suma"), de fiecare data cu valori diferite pentru cei 2
        parametri. Asta a facut ca valoarea returnata sa fie diferita de fiecare data. Apoi am luat fiecare valoare
        returnata si am dat-o ca parametru catre functia console.log, care o afiseaza in consola.
      </p>
    </div>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
