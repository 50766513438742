import React from "react";
import Notion from "../../Notion/Notion";
import Important from "../../Important/Important";

function LESSON_RO_ABSTRACT() {
  return <p>Ce reprezinta de fapt programarea? De ce sa invatam asta, si cum incepem?</p>;
}

function LESSON_RO_PAGE() {
  return (
    <>
      <LESSON_RO_ABSTRACT />
      <p>
        Atunci cand interactionam cu alti oameni, folosim un limbaj uman, fie el limba romana, engleza, sau una din
        miile de alte limbi si dialecte. Astfel, putem comunica intentii, dorinte si nevoi. In acelasi mod in care
        comunicam cu oamenii, avem nevoie sa comunicam si cu un computer. Diferenta principala intre cele 2 tipuri de
        interactiune este ca oamenii au inteligenta, si prin urmare context.
      </p>
      <p>
        Comunicarea intre 2 oameni, daca o analizam cu atentie, este plina de ambiguitate. Lucrul ce asigura (de cele
        mai multe ori) ca 2 persoane se inteleg reciproc este o experienta si cultura comuna, care ii ajuta sa
        interpreteze ambiguitatile intr-un mod similar. Putem observa reversul acestei situatii atunci cand 2 oameni din
        2 culturi sau medii diferite incearca sa comunice: poate sunt din 2 tari care au nivele drastic diferite de
        dezvoltare, din diferite religii, sau pur si simplu au crescut in medii economice si culturale foarte diferite.
        In acel caz, pot aparea foarte usor probleme in comunicare, iar acestea duc la o reactie tipic umana:
        frustrarea.
      </p>

      <p>
        In lumea computerelor, echivalentul ar fi o eroare. Dat fiind faptul ca un computer (cel putin unul din
        generatiile de pana acum) nu are inteligenta sau context, comunicarea trebuie sa fie lipsita de orice
        ambiguitate. Asta face ca limbajul normal al oamenilor sa fie pur si simplu inadecvat comunicarii cu un
        computer. Oamenii nu au inventat limbaje de programare din dorinta de a creea ceva diferit, ci doar pentru ca
        limbajele existente nu puteau satisface cerintele de exactitate si precizie ale domeniului, in acelasi mod in
        care matematica are nevoie de notatie specifica pentru a exprima intr-un mod cat mai corect si concis o formula.
      </p>

      <p>
        Desi exista foarte multe limbaje de programare, nu putem spune ca un anumit limbaj este cel mai bun. Daca plecam
        de la ideea ca un limbaj de programare este doar o unealta folosita intr-un anumit scop, atunci concluzia este
        ca fiecare limbaj va fi mai bun la a rezolva anumite probleme, si prin definitie, asta il face mai putin
        potrivit pentru altele. In acelasi mod nu putem spune ca o masina este mai buna decat un avion ca mijloc de
        transport, din moment ce fiecare este mai potrivit pentru o anumita categorie de situatii.
      </p>

      <Important label="In lumea tehnica, orice solutie, la orice problema, reprezinta un compromis." />
      <br />
      <p>
        Un limbaj de programare poate permite programatorului sa fie mai explicit decat un altul. De obicei, asta face
        acel limbaj sa fie perceput ca fiind mai "dificil". Aceasta dificultate se manifesta de cele mai multe ori prin
        faptul ca precizia oferita are ca pret lungimea programelor. Cu alte cuvinte, avem un compromis intre usurinta
        de folosire si performanta. Acest compromis este familiar oricarei persoane care a comparat vreodata un articol
        generic, destinat consumului in masa, cu unul construit pentru performanta.
      </p>

      <p>
        Limbajul pe care il vom studia este <Notion label="TypeScript" />. El are ca baza un alt limbaj foarte popular,
        numit <Notion label="JavaScript" />. Am ales sa folosim TypeScript pentru precizia imbunatatita pe care o ofera
        fata de JavaScript, pastrand in acelasi timp portabilitatea acestuia. JavaScript este aproape singurul limbaj
        care poate rula intr-un web browser. El se afla la baza partii vizuale a tuturor aplicatiilor web pe care toti
        le folosim (Google, Youtube, Facebook etc). Dat fiind faptul ca este construit pe baza limbajului JavaScript,
        putem folosi TypeScript oriunde am folosi JavaScript. Diferentele vor deveni mai clare pe parcursul cursului,
        dar am vrut sa introduc de la inceput motivul pentru care am ales acest limbaj.
      </p>

      <p>Acum, cateva notiuni elementare si nume folosite in programare:</p>
      <ul>
        <li>
          Un program creat cu ajutorul unui limbaj de programare este scris in "cod". Asta face un programator sa spuna
          ca "scrie un program", o expresie foarte des intalnita.
        </li>
        <li>
          Codul este un nume fara plural, prin urmare nu putem spune ca scriem "coduri". Intotdeauna este folosit la
          singular.
        </li>
        <li>
          Codul este scris de obicei ori intr-un <Notion label="editor de text" />, ori intr-un <Notion label="IDE" />.
          Un IDE are toata functionalitatea unui editor de text, plus alte unelte care ajuta de multe ori programatorii
          sa scrie cod mai repede si cu mai putine greseli. Asta il face sa fie mai complex, si prin urmare unii
          programatori prefera sa nu foloseasca un IDE.
        </li>
        <li>
          In TypeScript, codul este interpretat de catre computer incepand de sus in jos si de la stanga la dreapta.
          Daca avem paranteze, atunci parantezele sunt interpretate inainte de restul codului de pe un anumit rand.
        </li>
        <li>
          Un rand de cod poarta denumirea de o <Notion label="linie de cod" />. De obicei, editoarele de text
          numeroteaza fiecare linie, iar aceasta numerotare ne ajuta sa ne orientam mai usor atunci cand ajungem sa avem
          fisiere foarte mari.
        </li>
        <li>
          Fiecare program scris vreodata are la baza cod, intr-unul sau mai multe limbaje. Totalitatea codului, si
          implicit a programelor, creeaza ceea ce numim <Notion label="software" />.
        </li>
        <li>
          Componentele fizice ale unui computer: procesorul, memoria RAM, placa de baza, ecranul si perifericele (mouse,
          tastatura etc) sunt numite <Notion label="hardware" />.
        </li>
      </ul>

      <p>Pentru a putea sa comunicam cu un computer in TypeScript, trebuie sa invatam despre 5 lucruri fundamentale:</p>
      <ul>
        <li>
          <Notion label="Tipuri de date" />
        </li>
        <li>
          <Notion label="Variabile" />
        </li>
        <li>
          <Notion label="Conditii" />
        </li>
        <li>
          <Notion label="Functii" />
        </li>
        <li>
          <Notion label="Bucle (structuri repetitive)" />
        </li>
      </ul>

      <p>
        Cu aceste 5 notiuni, putem exprima orice fel de informatie si orice fel de actiune, in termeni pe care un
        computer ii poate intelege.
      </p>
    </>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
