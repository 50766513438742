import React from "react";
import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";

export const code1 = (
  <Code
    code={`// line comment

/*
  block comment
*/`}
  />
);

export const code2 = <CodeEditor defaultCode={`// var a:number = 0;\n// console.log(a)`} />;

export const code3 = <CodeEditor defaultCode={`var a:number = 4; // line comment`} />;

export const code4 = <CodeEditor defaultCode={`/*\n line1\n line2\n line3\n line4\n*/`} />;

export const code5 = (
  <CodeEditor
    stacked
    defaultCode={`var myName:string = "Mike";
console.log("myName: " + myName);`}
  />
);

export const code6 = (
  <CodeEditor
    stacked
    defaultCode={`var me:string = "Adam";
console.log("me:" + me);
console.log("me: " + me);`}
  />
);
