import React from "react";
import { Divider, Typography } from "antd";
import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import { code1, code2, code3, code4, code5, scalarTypes } from "./common";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      Orice aplicatie are nevoie de o sursa de date. In cazul aplicatiilor web, de cele mai multe ori, aceasta sursa de
      date este un <Notion label="API" />. API-urile sunt de mai multe tipuri, fiecare cu propriul mod de organizare a
      informatiilor. Cele mai multe API-uri moderne folosesc o tehnologie numita <Notion label="GraphQL" />, iar in
      continuare vom vedea de ce asta este o alegere foarte buna.
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <>
      <LESSON_RO_ABSTRACT />
      <p>
        <Notion label="GraphQL" /> ofera un mod de a face request-uri catre un server, in care descriem tipul de
        informatie pe care vrem sa il primim inapoi. GraphQL reprezinta doar nivelul de API, iar informatiile lui pot
        veni din orice tip de baze de date: <Notion label="MySQL" />, <Notion label="PostgreSQL" />,{" "}
        <Notion label="DynamoDB" />, <Notion label="MongoDB" />, sau orice altceva.
      </p>

      <br />
      <Divider>
        <Typography.Title level={2}>Schema</Typography.Title>
      </Divider>
      <p>
        Fiecare API care foloseste GraphQL este bazat pe o descriere a tuturor informatiilor pe care le poate oferi,
        numit <Notion label="schema" /> (este acelasi cuvant si in romana, si in engleza). Schema este scrisa intr-un
        limbaj numit <Notion label="SDL" />, care este foarte similar cu <Notion label="JSON" />. In aceasta schema, vom
        defini tipuri de date si operatiunile care ne permit sa interactionam cu ele.
      </p>

      <h3>Tipuri de date</h3>
      <p>
        In orice API, exista cel putin un tip de date, dar de obicei sunt mai multe. Daca avem un API care se afla in
        spatele unei carti de bucate digitale, atunci unul dintre tipurile de date ar fi o reteta -{" "}
        <Term label="Recipe" />. Fiecare tip de date pe care il declaram trebuie sa aiba cel putin o proprietate (numita
        si camp). In exemplul nostru, <Term label="Recipe" /> are 5 proprietati: <Term label="id" />,{" "}
        <Term label="name" />, <Term label="description" />, <Term label="photoUrl" /> si <Term label="ingredients" />.
      </p>

      {code1}

      <p>
        <Important
          label={
            <>
              Unele proprietati au un semn de exclamare la final (
              <Term label="!" />
              ), iar altele nu. Semnul de exclamare semnifica faptul ca acea proprietate este obligatorie, iar valoarea
              lui nu poate fi nula. Asta face proprietatile fara semnul de exclamare sa devina optionale.
            </>
          }
        />
      </p>
      <p>
        Fiecare proprietate are la randul ei un tip de date asociat. Exista o lista de tipuri de date primitive (un tip
        de date primitiv se numeste si <Notion label="scalar" />
        ):
      </p>

      {scalarTypes}

      <p>
        Pe langa tipurile scalare, fiecare tip de date pe care noi il definim poate fi asociat unei proprietati din alt
        tip, astfel creand o ierarhie. In exemplul nostru, tipul <Term label="Ingredient" /> este folosit pentru
        proprietatea <Term label="ingredients" /> din <Term label="Recipe" />. Parantezele patrate de la ingredients
        semnifica faptul ca acea proprietate are ca valoare o lista de elemente de tipul <Term label="Ingredient" />, nu
        doar unul singur. In acest fel, putem creea o structura organizata de date, care ne permite sa reprezentam orice
        informatie.
      </p>

      <h3>Queries si mutations</h3>

      <p>
        Dupa ce am definit tipurile de date, urmatorul pas este sa definim cum interactionam cu API-ul. In GraphQL,
        interactiunea se face prin <Notion notionKey="query" label="queries" />
        si <Notion notionKey="mutation" label="mutations" />. Acestea sunt niste moduri de a cere informatii de la API
        sau de a-i cere API-ului sa modifice niste informatii din baza de date.
      </p>

      <p>
        Pentru tipurile de date definite, am avea nevoie de 2 queries: unul pentru a cere toate retetele, iar altul
        pentru a cere o reteta individuala. Ele arata astfel:
      </p>

      {code2}

      <br />
      <p>
        Pe langa operatiile care ne permit sa cerem informatii de la API (queries), avem nevoie de operatii pentru a
        modifica datele. In primul rand, avem nevoie de un mod prin care sa creem o reteta. Pentru asta creem un
        mutation:
      </p>

      {code3}

      <p>
        In corpul fiecare operatii am definit o lista de proprietati (
        <Term label="id" />, <Term label="name" />, <Term label="description" />
        , <Term label="photoUrl" />, <Term label="ingredients" />
        ). Fiecare query si mutation are capacitatea de a returna o serie de proprietati pentru fiecare element
        returnat, iar in acest fel specificam care sunt acele proprietati. Ele trebuie sa fie alese dintre proprietatile
        definite pentru tipul de date (in exemplul nostru, acest tip de date este <Term label="Recipe" />
        ).
      </p>

      <p>
        <Important label="Atat queries, cat si mutations pot folosi variabile." />{" "}
      </p>

      <p>
        De exemplu, daca cerem o lista de retete (
        <Term label="ListRecipes" />
        ), nu avem neaparat nevoie sa comunicam API-ului vreo informatie suplimentara, in schimb daca cerem o singura
        reteta, trebui sa specificam care este ID-ul retetei cautate. Putem face asta cu ajutorul unei variabile.
      </p>

      <p>
        Apoi, daca vrem sa adaugam o reteta in baza de date (
        <Term label="CreateRecipe" />
        ), trebuie sa ii comunicam API-ului detaliile retetei pe care urmeaza sa o creem.
      </p>

      <p>
        In mutatia din exemplul nostru, numita <Term label="CreateRecipe" />, variabila <Term label="$input" />
        are un tip de date asociat, iar acesta este <Term label="CreateRecipeInput" />. Acest tip trebuie definit la
        randul lui, iar in interiorul lui vom defini cate o proprietate pentru fiecare informatie de care avem nevoie
        pentru a crea un element de tipul <Term label="Recipe" />. In mutatia noastra, <Term label="$input" /> este
        marcat ca fiind obligatoriu pentru ca fara el, nu am avea nicio informatie despre reteta pe care vrem sa o
        creem.
      </p>

      <p>
        Pentru a defini <Term label="CreateRecipeInput" />, putem creea un tip de date special, definit cu cuvantul
        cheie <Term label="input" />, in loc de <Term label="type" />. Putem face asta astfel:
      </p>

      {code4}

      <p>
        Fiecare proprietate pe care am facut-o obligatorie in definitia tipului
        <Term label="Recipe" /> trebuie sa fie obligatorie si in
        <Term label="CreateRecipeInput" />, altfel putem primi o eroare dupa ce creem reteta, atunci cand incercam sa o
        cerem de la API.
      </p>

      <p>Pana acum, intreaga schema a API-ului nostru arata astfel:</p>

      {code5}

      <h3>Resolvers</h3>

      <p>
        Fiecare query si mutation pe care l-am definit in schema trebuie sa aiba in spate o functie care este
        responsabila pentru a face legatura intre schema si baza de date. Aceste functii se numesc{" "}
        <Notion label="resolvers" notionKey="resolver" />. Fara resolvers, API-ul nostru nu face nimic. Ei sunt
        responsabili pentru efectuarea propriu-zisa a operatiilor pe care le descrie schema: ei aduc elementele din baza
        de date, le modifica, le creeaza sau le sterg. In functie de ce unelte folosim pentru a ne creea API-ul, aceste
        functii trebuie create de noi, ca programatori, sau pot fi generate in mod automat de catre unealta folosita, pe
        baza informatiilor definite in schema. In majoritatea situatiilor, operatiile sunt standard, asa ca ne putem
        baza pe o unealta automata pentru acest past. Exista si cazuri specifice, in care trebuie sa creem resolvers
        manual, dar nu vom discuta despre asta acum.
      </p>
    </>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
