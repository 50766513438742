import React from "react";
// import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";

export const code1 = (
  <CodeEditor
    defaultCode={`var myName:string = "Emily";
console.log(myName);`}
  ></CodeEditor>
);

export const code2 = (
  <CodeEditor
    defaultCode={`var myName:string = "Emily";
console.log(myName);

var yourName:string = "Isabella";
console.log(yourName);

var myAge:number = 20;
var yourAge:number = 35;

console.log(myAge);
console.log(yourAge);`}
  />
);

export const code3 = (
  <CodeEditor
    stacked
    defaultCode={`var a:number = 10;
var b:number = 15;
console.log(a + b);

console.log("Hello" + " " + "World");

var me:string = "Jake";
var you:string = "Chris";

console.log("I am " + me + ". You are " + you + ".");`}
  />
);

export const code4 = (
  <CodeEditor
    stacked
    defaultCode={`var myName:string = "Mike";
console.log(myName);`}
  />
);

export const code5 = (
  <CodeEditor
    stacked
    defaultCode={`var myName:string = "Mike";
console.log("myName: " + myName);`}
  />
);

export const code6 = (
  <CodeEditor
    stacked
    defaultCode={`var me:string = "Adam";
console.log("me:" + me);
console.log("me: " + me);`}
  />
);
