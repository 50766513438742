import React from "react";
import { Divider, Typography } from "antd";
// import Notion from "../../Notion/Notion";
// import Term from "../../Term/Term";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
import * as common from "./common";
// import Code from "../../Code/Code";
import translate from "../../common/translate";
// import CodeEditor from "../../CodeEditor/CodeEditor";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      Comentariile ne permit sa ii comunicam computerului ca anumite parti dintr-un program nu trebuie sa fie evaluate
      si ca sunt doar "de decor".
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <>
      <LESSON_RO_ABSTRACT />
      <p>
        Comentariile sunt folosite de cele mai multe ori pentru a "lua notite" in codul propriu-zis. Daca avem o
        situatie in care o sectiune de cod este foarte complexa sau poate neclara, putem scrie un comentariu care
        explica de ce am ales sa scriem acel cod in felul in care am facut-o.
      </p>
      <p>
        Culoarea textului care face parte dintr-un comentariu va fi de obicei gri. Bineinteles, asta depinde de editorul
        folosit si ce setari ai ales, dar ca regula, atunci cand vedem o sectiune de cod gri, de obicei inseamna ca este
        un comentariu.
      </p>
      <Important label="Indiferent de tipul comentariilor, putem adauga oricat de multe dorim, oriunde in programul nostru." />
      <br />
      <p>In TypeScript, exista 2 tipuri de comentarii: de tip linie si de tip bloc. Ele arata astfel:</p>
      {common.code1}
      <br />

      <Divider>
        <Typography.Title level={2}>line comment</Typography.Title>
      </Divider>
      <p>
        Atunci cand avem un comentariu de tip linie (line comment), ii marcam inceputul 2 slash-uri: <b>{"//"}</b>.
        Orice text scris dupa acest semn, pe aceeasi linie, este ignorat de catre computer. In exemplul urmator,
        incearca sa stergi semnele care marcheaza cele 2 line comments si observa ce se intampla.
      </p>
      {common.code2}
      <p>
        Un line comment nu trebuie sa fie neaparat de la inceputul liniei. Putem avea cod real inainte de comentariu,
        iar apoi putem adauga slash-urile si putem scrie textul comentariului, astfel:
      </p>
      {common.code3}
      <p>
        Aceasta situatie este des intalnita atunci cand vrem sa adaugam o scurta explicatie referitoare la numele si/sau
        rolul unei declaratii de variabila.
      </p>

      <Exercise
        label={`${translate("Exercise")} 1`}
        requirement={
          <>
            <span>Transforma codul dat intr-un line comment</span>
          </>
        }
        defaultCode={`var me:string = 'Adam';`}
        solution={[`// var me:string = 'Adam';`]}
        expectedLog="17"
      />

      <br />
      <Divider>
        <Typography.Title level={2}>block comment</Typography.Title>
      </Divider>

      <p>
        Desi line comments sunt foarte utile pentru a scrie scurte explicatii, ele devin greu de mentinut atunci cand
        vrem sa adaugam un paragraf, sau chiar mai mult. Pentru astfel de situatii, avem block comments. Ele ne permit
        sa marcam inceputul si sfarsitul comentariului, iar astfel putem "imbraca" oricat de mult text intr-un singur
        comentariu:
      </p>

      {common.code4}
      <p>
        Pentru a marca inceputul unui block comment, folosim un slash, urmat de un asterisk: <b>{"/*"}</b>. <br />
        Pentru a marca finalul comentariului, punem mai intai asterisk-ul si apoi slash-ul: <b>*/</b>.
      </p>
      <p>
        Intre marker-ul de inceput si cel de final, putem avea oricate linii de text care nu vor fi interpretate in
        niciun fel de catre computer. Este pur si simplu ca si cum computerul nu le vede. Ele sunt utile doar noua, ca
        programatori, pentru a intelege mai bine care este scopul programului sau pentru a explica anumite complexitati
        tehnice.
      </p>
      <Exercise
        label={`${translate("Exercise")} 2`}
        requirement={
          <>
            <span>
              Transforma tot "codul" dat intr-un block comment. <br /> Daca ai facut-o corect, eroarea va disparea.
            </span>
          </>
        }
        defaultCode={`this\nis\na\nblock\ncomment`}
        solution={[`/*\nthis\nis\na\nblock\ncomment\n*/`]}
        expectedLog="17"
      />
    </>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
