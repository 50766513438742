import React from "react";
import { Link } from "react-router-dom";
import { Typography, Row, Col } from "antd";

import LanguagePicker from "../LanguagePicker/LanguagePicker";

import Card from "../Card/Card";

import AppLogo from "./godragons_academy_logo_dark_text.png";
import "./MainBar.scss";

export default function MainBar({ pageTitle = "", language, setLanguage }) {
  return (
    <Card className="main-bar">
      <Row align="middle">
        <Col span={6} className="logo-container">
          <Link to="/">
            <img src={AppLogo} className="app-logo" alt="" />
          </Link>
        </Col>
        <Col span={10}>
          <Typography.Title level={4} className="page-title">
            {pageTitle}
          </Typography.Title>
        </Col>
        <Col span={8}>
          <LanguagePicker language={language} setLanguage={setLanguage} />
        </Col>
      </Row>
    </Card>
  );
}
