import React from "react";
import cx from "classnames";
import { Spin, Result, Button } from "antd";
import { Link } from "react-router-dom";

import "./LoadingWrapper.scss";

export default function LoadingWrapper({
  content,
  className,
  isLoading,
  error,
}) {
  if (isLoading) {
    return (
      <div className="loading-screen">
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <Result
        status={error}
        title={error}
        subTitle="Sorry, the page you are looking for does not exist."
        extra={
          <Link to="/">
            <Button type="primary">Back to Dashboard</Button>
          </Link>
        }
      />
    );
  }

  return <div className={cx(className)}>{content()}</div>;
}
