import React from "react";
import { Row, Col, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Editor from "react-simple-code-editor";
import cx from "classnames";

import { highlight } from "prismjs/components/prism-core";
import Prism from "prismjs";
import _ from "lodash";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-markup-templating";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-graphql";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";

import "./CodeEditor.scss";
import translate from "../common/translate";

const CODE_EXECUTION_TIMEOUT_THREHSHOLD = 1000;
const DEBOUNCE_TIMEOUT = 2000;

export default class CodeEditor extends React.Component {
  constructor() {
    super();
    this.state = {
      code: "",
      result: "",
      logs: [],
      error: null,
      errorLineNumber: null,
      language: "ts",
      tree: null,
      isExecuting: true,
    };
    this.debouncedExecute = _.debounce(this.execute, DEBOUNCE_TIMEOUT);
  }

  componentDidMount() {
    const defaultCode = this.props.defaultCode || "";
    this.execute(defaultCode);
    this.setState({ code: defaultCode });
  }

  limitEval = (code, timeoutThreshold) => {
    return new Promise((resolve) => {
      const logs = [];
      var id = Date.now(),
        blob = new Blob(
          [
            /*javascript*/ `
            onmessage = function(message) {
              try {
                var messageContent = message.data; 
            
                postMessage({
                  id: messageContent.id + 1
                });
  
                const result = eval.call(this, "function safeLog() { postMessage({log: Array.from(arguments)})}" + messageContent.code);
  
                postMessage({
                  result, 
                  id: messageContent.id
                })
              } catch(e) {
                postMessage({
                  error: e, 
                  id: messageContent.id
                })
              }
            };
          `,
          ],
          { type: "text/javascript" }
        ),
        myWorker = new Worker(URL.createObjectURL(blob));

      function onDone(params) {
        URL.revokeObjectURL(blob);
        resolve(params);
      }

      myWorker.onmessage = function (message) {
        const messageContent = message.data;
        if (messageContent) {
          if (messageContent.log) {
            if (logs.length < 1000) {
              logs.push(messageContent.log);
            } else {
              const TOO_MANY_LOGS = "... Too many logs";
              // eslint-disable-next-line eqeqeq
              if (Array.from(logs[logs.length - 1]).join("") != TOO_MANY_LOGS) {
                logs.push([TOO_MANY_LOGS]);
              }
            }
            return;
          }
          if (messageContent.id === id) {
            id = 0;
            onDone({
              timeout: false,
              returnValue: messageContent.result,
              error: messageContent.error,
              logs,
            });
          } else if (messageContent.id === id + 1) {
            setTimeout(function () {
              if (id) {
                myWorker.terminate();
                onDone({ timeout: true, logs });
              }
            }, timeoutThreshold || CODE_EXECUTION_TIMEOUT_THREHSHOLD);
          }
        }
      };
      myWorker.postMessage({ code, id });
    });
  };

  execute = async (code) => {
    try {
      let logs = [];

      const jsCode = window.ts.transpile(code);

      const tree = window.esprima.parseScript(jsCode)?.body;
      const safeCode = jsCode.split("console.log").join("safeLog");

      let codeToRun = safeCode;

      this.setState({ isExecuting: true });
      const evalResponse = await this.limitEval(codeToRun);
      this.setState({ isExecuting: false });

      let error = null;
      if (evalResponse.timeout) {
        error = translate("CodeExecutionTimeout");
      }
      if (evalResponse.error) {
        error = evalResponse.error.message;
        console.log("error from eval:", evalResponse.error);
      }

      logs = evalResponse.logs || [];
      this.setState({
        result: evalResponse.returnValue,
        logs,
        error,
        tree,
      });

      if (this.props.onEval) {
        this.props.onEval({ tree, code, logs, hasError: false });
      }
    } catch (e) {
      let errorMessage = e.message;
      const fullError = e.stack;
      if (!fullError) {
        this.setState({ error: e });
        if (this.props.onEval) {
          this.props.onEval({ code, hasError: true });
        }
        return;
      }
      const lineNumberMatches = fullError.match(/.+<anonymous>:(\d+):.+/g);
      let lineNumberMatch = "";
      let lineNumber = "";
      if (lineNumberMatches) {
        lineNumberMatch = lineNumberMatches[0];
        const targetString = "<anonymous>:";
        const anonymousPosition = lineNumberMatch.indexOf(targetString);
        const lineNumberMatchFirstPart = lineNumberMatch.substring(
          anonymousPosition + targetString.length
        );
        lineNumber = lineNumberMatchFirstPart.split(":")[0];
      }

      this.setState({
        result: null,
        error: errorMessage,
        errorLineNumber: lineNumber,
      });

      if (this.props.onEval) {
        this.props.onEval({ code, hasError: true });
      }
    }
  };

  getLogHTML = (log) => {
    const { language } = this.state;
    return highlight(log.join(" "), Prism.languages[language], language);
  };

  displayConsole = () => {
    const { error, logs } = this.state;
    const { consoleTitle = translate("Console"), stacked } = this.props;
    if (error) {
      return (
        <p className="error">
          <b>Error:</b> <br />
          <span className="error-message">{error}</span>
        </p>
      );
    }

    // if (!logs || logs.length === 0) {
    //   return null;
    // }

    return (
      <Col xl={stacked ? 24 : 12} xs={24}>
        <div className="console-container">
          <Typography.Title level={3} className="section-title">
            {consoleTitle}
          </Typography.Title>
          <div className="console">
            {logs.map((log, i) => (
              <span className="log-row" key={i}>
                <span
                  className="log-row"
                  dangerouslySetInnerHTML={{
                    __html: log.join(" ").split(" ").join("&nbsp"),
                  }}
                />
              </span>
            ))}
          </div>
        </div>
      </Col>
    );
  };

  render() {
    const { language, code, isExecuting /*errorLineNumber*/ } = this.state;
    const { codeTitle = translate("Code"), stacked } = this.props;

    return (
      <div className={cx("code-editor-container", { stacked })}>
        <Row gutter={[16, 16]}>
          <Col xl={stacked ? 24 : 12} xs={24}>
            <Typography.Title level={3} className="section-title">
              {codeTitle}{" "}
              {isExecuting ? (
                <span className="is-executing-marker">
                  <LoadingOutlined /> {translate("IsExecuting")}
                </span>
              ) : null}
            </Typography.Title>
            <div className="code-editor">
              <div className="line-numbers">
                {code.split(/\r\n|\r|\n/).map((_, i) => (
                  <span className="line-number" key={i}>
                    {i + 1}
                  </span>
                ))}
              </div>
              <Editor
                value={this.state.code}
                onValueChange={(code) => {
                  this.debouncedExecute(code);
                  this.setState({ code });
                }}
                onBlur={() => {
                  this.execute(this.state.code);
                }}
                highlight={(code) =>
                  highlight(code, Prism.languages[language], language)
                }
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 12,
                }}
              />
            </div>
          </Col>
          {this.displayConsole()}
        </Row>
      </div>
    );
  }
}
