import React from "react";
import { Modal, Button, Typography } from "antd";
import { CloseCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import CodeEditor from "../CodeEditor/CodeEditor";
import translate from "../common/translate";

import "./Exercise.scss";

export default class Exercise extends React.Component {
  state = {
    isOpen: false,
    isSolved: null,
    problems: null,
    tree: null,
    code: null,
    logs: null,
    hasError: null,
    isSolutionVisible: false,
  };

  close = () => {
    this.setState({
      isOpen: false,
      isSolved: null,
      problems: null,
      logs: null,
      tree: null,
      code: null,
      hasError: null,
    });
  };

  componentDidMount() {
    this.setState({ code: this.props.defaultCode });
  }

  onEval = ({ tree, code, logs, hasError }) => {
    this.setState({ tree, code, logs, hasError });
  };

  computeIsSolved() {
    const { /*tree, */ code = "", logs, hasError } = this.state;
    const { solution, expectedCode, expectedLog, wrongSolution } = this.props;

    let problems = [];
    let isSolved = false;

    if (expectedLog) {
      console.log("we have expected log");
      const logsStr = (logs || []).join("\n");
      console.log("logs = ", logs);
      console.log("logsStr = ", logsStr);
      console.log("expectedLog = ", expectedLog);
      let logMatches = false;

      // we can either provide one expected log, or an array
      if (Array.isArray(expectedLog)) {
        console.log("multiple options for expectedLog");
        for (let i = 0; i < expectedLog.length; i++) {
          if (expectedLog[i] === logsStr) {
            logMatches = true;
            isSolved = true;
            return { problems, isSolved };
          }
        }
      } else {
        if (expectedLog === logsStr) {
          logMatches = true;
        }
      }

      if (logMatches) {
        isSolved = true;
        return { problems, isSolved };
      } else {
        problems = [translate("NegativeExerciseFeedback")];
        return { problems, isSolved };
      }
    }

    if (expectedCode) {
      for (let i = 0; i < (expectedCode || solution).length; i++) {
        const expectedCodeVersion = (expectedCode || solution)[i];
        if (
          this.solutionsMatch({ expected: expectedCodeVersion, actual: code })
        ) {
          isSolved = true;
          return { problems, isSolved };
        }
      }
    }

    if (!isSolved) {
      problems = [translate("NegativeExerciseFeedback")];
    }

    if (wrongSolution) {
      for (let i = 0; i < wrongSolution.length; i++) {
        const wrongSolutionDetails = wrongSolution[i];

        if (
          this.solutionIncludes({
            expected: wrongSolutionDetails.code,
            actual: code,
          })
        ) {
          isSolved = false;
          problems = wrongSolutionDetails.problems || [
            translate("ErrorExerciseFeedback"),
          ];
          return { problems, isSolved };
        }
      }
    }

    if (hasError) {
      problems = [translate("ErrorExerciseFeedback")];
      return { problems, isSolved };
    }

    return { problems, isSolved };
  }

  submit = () => {
    const { problems, isSolved } = this.computeIsSolved();
    // const targetTree = JSON.stringify(tree) + "aa";
    // let strTree = JSON.stringify(tree);
    // if (targetTree !== strTree) {
    //   problems = [translate("NegativeExerciseFeedback")];
    // } else {
    //   isSolved = true;
    // }
    this.setState({ problems, isSolved });
    // console.log("onCodeEval() tree = ", tree);
  };

  solutionsMatch = ({ expected, actual }) => {
    const matchSpaces = / |\r\n|\n|\r/gm;
    const expectedWithoutSpaces = (expected || "").replace(matchSpaces, "");
    const actualWithoutSpaces = (actual || "").replace(matchSpaces, "");
    const expectedWithoutSemicolons = expectedWithoutSpaces.split(";").join("");
    const actualWithoutSemicolons = actualWithoutSpaces.split(";").join("");
    const expectedWithMatchedQuotes = expectedWithoutSemicolons
      .split("'")
      .join('"');
    const actualWithMatchedQuotes = actualWithoutSemicolons
      .split("'")
      .join('"');
    // console.log("solutionsMatch() expected = ", expected);
    // console.log("solutionsMatch() actual = ", actual);
    return expectedWithMatchedQuotes === actualWithMatchedQuotes;
  };

  solutionIncludes = ({ expected, actual }) => {
    const matchSpaces = / |\r\n|\n|\r/gm;
    // console.log("solutionIncludes() expected = ", expected);
    // console.log("solutionIncludes() actual = ", actual);
    const expectedWithoutSpaces = (expected || "").replace(matchSpaces, "");
    const actualWithoutSpaces = (actual || "").replace(matchSpaces, "");
    const expectedWithoutSemicolons = expectedWithoutSpaces.split(";").join("");
    const actualWithoutSemicolons = actualWithoutSpaces.split(";").join("");
    const expectedWithMatchedQuotes = expectedWithoutSemicolons
      .split("'")
      .join('"');
    const actualWithMatchedQuotes = actualWithoutSemicolons
      .split("'")
      .join('"');

    return actualWithMatchedQuotes.includes(expectedWithMatchedQuotes);
  };

  render() {
    const {
      requirement,
      defaultCode,
      label,
      expectedCode,
      solution,
    } = this.props;
    const { problems, isSolved, isOpen, isSolutionVisible } = this.state;

    return (
      <>
        <Button
          className="exercise-trigger"
          type="primary"
          onClick={() => this.setState({ isOpen: true })}
        >
          {label}
        </Button>
        <Modal
          title={label}
          visible={isOpen}
          maskClosable={true}
          onCancel={() => {
            this.close();
          }}
          footer={null}
          className="exercise"
        >
          <Typography.Title level={3} className="requirement-title">
            {translate("Requirement")}
          </Typography.Title>
          <div className="requirement">{requirement}</div>
          <CodeEditor
            onEval={this.onEval}
            defaultCode={defaultCode}
            stacked={this.props.stacked}
          />
          <div className="footer">
            <div>
              <Button type="primary" onClick={this.submit}>
                {translate("SubmitExercise")}
              </Button>
              <Button
                className="show-solution"
                onClick={() =>
                  this.setState({ isSolutionVisible: !isSolutionVisible })
                }
              >
                {isSolutionVisible
                  ? translate("HideSolution")
                  : translate("ShowSolution")}
              </Button>
            </div>
            <div>
              {isSolved && (
                <span className="is-solved">
                  <CheckCircleFilled /> {translate("PositiveExerciseFeedback")}
                </span>
              )}
              {problems &&
                problems.length > 0 &&
                problems.map((problem, i) => (
                  <React.Fragment key={i}>
                    <span className="problem">
                      {" "}
                      <CloseCircleOutlined />
                      {problem}
                    </span>
                  </React.Fragment>
                ))}
            </div>
            <div>
              {isSolutionVisible ? (
                <div className="solution-editor">
                  {(solution || expectedCode).map((codeVersion, i) => (
                    <CodeEditor
                      key={i}
                      stacked={this.props.stacked}
                      defaultCode={codeVersion}
                      codeTitle={`${translate("Code")} (${translate(
                        "Solution"
                      )} ${i + 1}) `}
                      consoleTitle={`${translate("Console")} (${translate(
                        "Solution"
                      )}) `}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
