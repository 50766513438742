import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

import translate from "../common/translate";

import "./LessonPage.scss";

export default function LessonPage({ title, content, language, i }) {
  return (
    <div className="lesson-page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">{translate("Lessons")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <b>{translate(title)}</b>
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="lesson-title">
        {i + 1}. {translate(title)}
      </h1>
      {content[language].page}
    </div>
  );
}
