import React from "react";
import { Divider, Typography, Row, Col } from "antd";
import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
// import * as common from "./common";
import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";
import translate from "../../common/translate";

import "./common.scss";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      Metodele oferite de array-uri ne permit sa lucram usor cu date organizate
      sub forma de lista: sa filtram, ordonam, gasim elemente dupa anumite
      criterii, sau pur si simplu sa adaugam sau sa stergem elemente.
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <div className="lesson-functions">
      <LESSON_RO_ABSTRACT />
      <p>
        In aceasta lectie vom studia pe rand fiecare dintre metodele esentiale
        oferite de tipul Array (exista mai multe metode, dar noi le vom studia
        doar pe cele mai des folosite. Pe restul le poti gasi{" "}
        <b>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array#Instance_methods"
          >
            aici
          </a>
        </b>
        ): numele metodei, ce parametri primeste, si ce valoarea returneaza. In
        acest mod este organizata intotdeauna documentatia unui limbaj, ceea ce
        inseamna ca urmarind lectia, vom invata atat cum sa folosim metodele
        array-urilor, dar si cum sa citim documentatie tehnica.
      </p>

      <p>
        <b> Adaugare/stergere elemente</b>
      </p>
      <ul>
        <li>
          <a href="#push">push</a>
        </li>
        <li>
          <a href="#pop">pop</a>
        </li>
        <li>
          <a href="#unshift">unshift</a>
        </li>
        <li>
          <a href="#shift">shift</a>
        </li>
        <li>
          <a href="#splice">splice</a>
        </li>
      </ul>

      <p>
        <b>Citire a unei sectiuni (felii) din array:</b>
      </p>
      <ul>
        <li>
          <a href="#slice">slice</a>
        </li>
      </ul>

      <p>
        <b>
          Caz special (pentru ca este o functie generica, si poate juca rolul
          tuturor celorlalte functii listate dupa ea)
        </b>
      </p>
      <ul>
        <li>
          <a href="#foreach">forEach</a>
        </li>
      </ul>
      <p>
        <b>Cautare elemente</b>
      </p>
      <ul>
        <li>
          <a href="#find">find</a>
        </li>
        <li>
          <a href="#includes">includes</a>
        </li>
        <li>
          <a href="#some">some</a>
        </li>
        <li>
          <a href="#every">every</a>
        </li>
      </ul>

      <p>
        <b>Modificare a array-ului</b>
      </p>
      <ul>
        <li>
          <a href="#filter">filter</a>
        </li>
        <li>
          <a href="#join">join</a>
        </li>
        <li>
          <a href="#map">map</a>
        </li>

        <li>
          <a href="#sort">sort</a>
        </li>
      </ul>

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="push">
          push
        </Typography.Title>
      </Divider>
      <p>
        Adauga unul sau mai multe elemente la <b>finalul</b> unui array si
        returneaza lungimea noului array.
      </p>
      <p>
        Sintaxa: <Code inline code="arr.push([element1[, ...[, elementN]]])" />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod push()"
        defaultCode={`var people: string[] = ["John", "Billy"];

console.log("people before push:", people);

people.push("Sam", "Rachel");
console.log("people after push:", people);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="pop">
          pop
        </Typography.Title>
      </Divider>
      <p>
        Sterge <b>ultimul</b> element din array si il returneaza.
      </p>
      <p>
        Sintaxa: <Code inline code="arr.pop())" />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod pop()"
        defaultCode={`var people: string[] = ["John", "Billy"];

console.log("people before pop:", people);

var deletedElement: string = people.pop();
console.log("people after pop:", people);
console.log("deletedElement = ", deletedElement)`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="unshift">
          unshift
        </Typography.Title>
      </Divider>
      <p>
        Adauga unul sau mai multe elemente la <b>inceputul</b> unui array si
        returneaza lungimea noului array.
      </p>
      <p>
        Sintaxa: <Code inline code="arr.unshift(element)" />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod unshift()"
        defaultCode={`var people: string[] = ["John", "Billy"];

console.log("people before unshift:", people);

people.unshift("Sam", "Rachel");
console.log("people after unshift:", people);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="shift">
          shift
        </Typography.Title>
      </Divider>
      <p>
        Sterge <b>primul</b> element din array si il returneaza.
      </p>
      <p>
        Sintaxa: <Code inline code="arr.shift())" />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod shift()"
        defaultCode={`var people: string[] = ["John", "Billy"];

console.log("people before shift:", people);

var deletedElement: string = people.shift();
console.log("people after shift:", people);
console.log("deletedElement = ", deletedElement)`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="splice">
          splice
        </Typography.Title>
      </Divider>
      <p>
        In traducere, "to splice" inseamna "a lipi". Motivul pentru acest nume
        este ca functia "splice" poate sterge, inlocui si adauga elemente, dar
        modul in care ar trebui sa o privim este ca alipim noi elemente celor
        existente. Functia modifica array-ul original.
      </p>
      <p>
        Functia splice are la baza faptul ca specificam 3 lucruri:
        <br />
        <br />
        <ol>
          <li>De unde incepem sa stergem</li>
          <li>
            Cate elemente stergem - poate fi zero, daca vrem doar sa adaugam
          </li>
          <li>
            Ce elemente vrem sa adaugam in locul celor sterse - daca de exemplu
            am sters 2 elemente si adaugam 3, asta inseamna ca primele 2
            elemente adaugate le vor lua locul primelor 2 sterse, iar al treilea
            este inserat imediat dupa ele
          </li>
        </ol>
      </p>
      <p>
        Sintaxa:{" "}
        <Code
          inline
          code="var arrDeletedItems: Array = arr.splice(start[, deleteCount[, item1[, item2[, ...]]]])"
        />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod splice() - stergere al doilea element"
        defaultCode={`var people: string[] = ["John", "Billy", "Sebastian"];

console.log("people before splice:", people);

people.splice(1, 1); // delete 1 element starting at index 1
console.log("people after splice:", people);`}
      />
      <CodeEditor
        stacked
        codeTitle="Exemplu cod splice() - adaugare element la indexul 1"
        defaultCode={`var people: string[] = ["John", "Billy", "Sebastian"];

console.log("people before splice:", people);

people.splice(1, 0, "Mary"); // insert "Mary" at index 1
console.log("people after splice:", people);`}
      />
      <CodeEditor
        stacked
        codeTitle="Exemplu cod splice() - inlocuirea ultimului element"
        defaultCode={`var people: string[] = ["John", "Billy", "Sebastian"];

console.log("people before splice:", people);

people.splice(people.length-1, 1, "Mary"); // replace "Sebastian" with "Mary"
console.log("people after splice:", people);`}
      />
      <CodeEditor
        stacked
        codeTitle="Exemplu cod splice() - inlocuirea primelor 2 elemente"
        defaultCode={`var people: string[] = ["John", "Billy", "Sebastian"];

console.log("people before splice:", people);

people.splice(0, 2, "Mary", "Richard"); // replace "John" and "Billy" with "Mary" and "Richard"
console.log("people after splice:", people);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="slice">
          slice
        </Typography.Title>
      </Divider>
      <p>
        Desi suna aproape identic cu "splice", sunt 2 functii total diferite.
        Functia slice returneaza o sectiune din array (in limba engleza, "slice"
        inseamna "felie"). Primeste 2 parametri: indexul de inceput si indexul
        de final.
      </p>
      <p>
        Returneaza o sectiune din array care include elementul aflat la indexul
        de inceput, dar nu il include pe cel elementul aflat la indexul de
        final. Nu modifica array-ul original.
      </p>

      <p>
        Sintaxa:{" "}
        <Code
          inline
          code="var section: Array = arr.slice(indexStart, indexEnd)"
        />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod slice()"
        defaultCode={`var people: string[] = ["John", "Billy", "Sebastian", "Mary"];

const firstThreePeople = people.slice(0, 3); // start at index 0 and end at index 3
console.log("firstThreePeople:", firstThreePeople);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="foreach">
          forEach
        </Typography.Title>
      </Divider>
      <p>
        In traducere, "for each" inseamna "pentru fiecare". Aceasta metoda
        apeleaza o functie (numita "callback") de atatea ori cate elemente ale
        array-ul, si da acestei functii ca parametru 3 valori:
        <ol>
          <li>elementul curent din array</li>
          <li>indexul elementului curent din array</li>
          <li>array-ul in sine (acest parametru nu este des folosit)</li>
        </ol>
        Metoda forEach nu returneaza nimic. Dat fiind faptul ca apeleaza un
        callback pentru fiecare element in parte, putem face cu "forEach"
        aproape orice putem face cu o bucla "for". Pentru acest motiv, "for" nu
        este foarte des folosit in programe reale scrise in TypeScript sau
        JavaScript. Exista 2 situatii principale unde un "for" este de preferat
        in loc de "forEach": atunci cand performanta este foarte importanta
        (pentru ca "for" se executa mai repede decat "forEach") si atunci cand
        avem de-a face cu cod asincron (vom discuta despre asta mai tarziu).
      </p>
      <p>
        Sintaxa:{" "}
        <Code
          inline
          code={`arr.forEach((currentValue, currentIndex, entireArray) => {
  // execute something
});`}
        />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod forEach()"
        defaultCode={`var people: string[] = ["John", "Billy", "Mark"];
people.forEach((currentPerson: string, currentIndex: number) => {
  console.log("currentPerson:", currentPerson, "currentIndex:", currentIndex);
})`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="find">
          find
        </Typography.Title>
      </Divider>
      <p>
        In limba engleza, "to find" inseamna "a gasi". Functia "find" este
        folosita pentru a gasi primul element din array care intruneste o
        anumita conditie.
      </p>
      <p>
        Ca si forEach, functia "find" primeste un callback. Parametrii pe care
        callback-ul ii primeste sunt aceiasi ca la forEach:
      </p>

      <ol>
        <li>elementul curent din array</li>
        <li>indexul elementului curent din array</li>
        <li>array-ul in sine (acest parametru nu este des folosit)</li>
      </ol>
      <p>
        Functia "find" returneaza primul element pentru care callback-ul
        returneaza o valoare <Notion label="truthy" />{" "}
      </p>
      <p>
        Sintaxa:{" "}
        <Code
          inline
          code={`arr.find((currentValue, currentIndex, entireArray) => {
  // execute something
});`}
        />
      </p>

      <CodeEditor
        stacked
        codeTitle='Exemplu cod find() - gaseste primul nume care incepe cu litera "B" '
        defaultCode={`var people: string[] = ["John", "Billy", "Mark"];
var name: string = people.find((currentPerson: string, currentIndex: number) => {
  if(currentPerson[0] === 'B') {
    return true;
  } else {
    return false;
  }
})
console.log('name:', name);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="includes">
          includes
        </Typography.Title>
      </Divider>
      <p>
        Functia "includes" este folosita pentru a afla daca un array contine un
        anumit element. Nu foloseste un callback. Returneaza true daca elementul
        a fost gasit si false daca nu a fost gasit.
      </p>

      <p>
        Sintaxa: <Code inline code={`arr.includes(target)`} />
      </p>

      <CodeEditor
        stacked
        codeTitle='Exemplu cod includes() - afla daca array-ul contine numele "Mark" '
        defaultCode={`var people: string[] = ["John", "Billy", "Mark"];
var result: boolean = people.includes("Mark");
console.log('result:', result);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="some">
          some
        </Typography.Title>
      </Divider>
      <p>
        In limba engleza, "some" inseamna "unii/unele". Functia "some" este
        folosita pentru a afla daca exista cel putin un element in array care
        intruneste o anumita conditie.
      </p>
      <p>
        Ca si forEach, functia "some" primeste un callback. Parametrii pe care
        callback-ul ii primeste sunt aceiasi ca la forEach:
      </p>

      <ol>
        <li>elementul curent din array</li>
        <li>indexul elementului curent din array</li>
        <li>array-ul in sine (acest parametru nu este des folosit)</li>
      </ol>
      <p>
        Functia "some" returneaza true daca pentru cel putin un element
        callback-ul returneaza o valoare <Notion label="truthy" />. Altfel,
        returneaza false.
      </p>
      <p>
        Sintaxa:{" "}
        <Code
          inline
          code={`arr.some((currentValue, currentIndex, entireArray) => {
  // execute something
});`}
        />
      </p>

      <CodeEditor
        stacked
        codeTitle='Exemplu cod some() - afla daca exista vreun nume care incepe cu litera "X" '
        defaultCode={`var people: string[] = ["John", "Billy", "Mark"];
var result: boolean = people.some((currentPerson: string, currentIndex: number) => {
  if(currentPerson[0] === 'X') {
    return true;
  } else {
    return false;
  }
})
console.log('result:', result);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="every">
          every
        </Typography.Title>
      </Divider>
      <p>
        In limba engleza, "every" inseamna "fiecare". Functia "every" este
        folosita pentru a afla daca <b>toate</b> elementele din array intrunesc
        o anumita conditie.
      </p>
      <p>
        Ca si forEach, functia "every" primeste un callback. Parametrii pe care
        callback-ul ii primeste sunt aceiasi ca la forEach:
      </p>

      <ol>
        <li>elementul curent din array</li>
        <li>indexul elementului curent din array</li>
        <li>array-ul in sine (acest parametru nu este des folosit)</li>
      </ol>
      <p>
        Functia "every" returneaza true daca pentru <b>toate</b> elementele
        callback-ul returneaza o valoare <Notion label="truthy" />. Altfel,
        returneaza false.
      </p>
      <p>
        Sintaxa:{" "}
        <Code
          inline
          code={`arr.every((currentValue, currentIndex, entireArray) => {
  // execute something
});`}
        />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod every() - afla daca toate numele au cel putin 3 litere "
        defaultCode={`var people: string[] = ["John", "Billy", "Mark"];
var result: boolean = people.every((currentPerson: string, currentIndex: number) => {
  if(currentPerson.length >= 3) {
    return true;
  } else {
    return false;
  }
})
console.log('result:', result);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="join">
          join
        </Typography.Title>
      </Divider>
      <p>
        In limba engleza, "join" inseamna "a uni". Functia "join" este folosita
        pentru a uni toate elementele dintr-un array intr-un string, folosind un
        alt string ca si separator dupa fiecare element.
      </p>
      <p>
        Ca si forEach, functia "nao" primeste un callback. Parametrii pe care
        callback-ul ii primeste sunt aceiasi ca la forEach:
      </p>

      <ol>
        <li>elementul curent din array</li>
        <li>indexul elementului curent din array</li>
        <li>array-ul in sine (acest parametru nu este des folosit)</li>
      </ol>
      <p>
        Sintaxa: <Code inline code={`arr.join(separator: string)`} />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod join() - uneste toate numele din lista folosind un underscore ca si separator "
        defaultCode={`var people: string[] = ["John", "Billy", "Mark"];
var result: boolean = people.join("_");
console.log('result:', result);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="map">
          map
        </Typography.Title>
      </Divider>
      <p>
        In limba engleza, "map" inseamna "a asocia" sau "harta". Functia "map"
        este folosita pentru a genera un nou array pe baza unui array existent.
        Array-ul rezultat va avea <b>intotdeauna</b> acelasi numar de elemente
        ca array-ul initial. Functia ne permite sa efectuam o transformare (cu
        ajutorul unui callback) pe fiecare element in parte, iar rezultatul sa
        fie folosit in noul array, la pozitia vechiului element.
      </p>
      <p>Nu modifica array-ul original.</p>
      <p>
        Ca si forEach, functia "every" primeste un callback. Parametrii pe care
        callback-ul ii primeste sunt aceiasi ca la forEach:
      </p>

      <ol>
        <li>elementul curent din array</li>
        <li>indexul elementului curent din array</li>
        <li>array-ul in sine (acest parametru nu este des folosit)</li>
      </ol>
      <p>
        Sintaxa:{" "}
        <Code
          inline
          code={`arr.map((currentValue, currentIndex, entireArray) => {
  // execute something
});`}
        />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod map() - transforma fiecare nume in litere mari "
        defaultCode={`var people: string[] = ["John", "Billy", "Mark"];
var result: string[] = people.map((currentPerson: string, currentIndex: number) => {
  return currentPerson.toUpperCase();
})
console.log('result:', result);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="sort">
          sort
        </Typography.Title>
      </Divider>
      <p>
        In limba engleza, "sort" inseamna "a ordona". Functia "sort" este
        folosita pentru a re-ordona elementele dintr-un array. Array-ul rezultat
        va avea <b>intotdeauna</b> acelasi numar de elemente ca array-ul
        initial. Functia ne permite sa ordonam elementele din array 2 cate 2,
        prin apelarea unui callback pentru fiecare pereche de elemente din
        array.
      </p>
      <p>Modifica array-ul original, dar si returneaza array-ul sortat.</p>
      <p>
        Ca si forEach, functia "sort" primeste un callback. Parametrii pe care
        callback-ul ii primeste sunt usor diferiti fata de forEach:
      </p>

      <ol>
        <li>elementul A din array</li>
        <li>elementul B din array</li>
      </ol>
      <p style={{ fontStyle: "italic" }}>
        cei 2 parametri formeaza perechea de elemente "curente"
      </p>
      <br />
      <p>
        Ordinea elementelor din array-ul rezultat este dictata de valoarea
        returnata din callback:
      </p>

      <ul>
        <li>
          Daca pentru o anumita pereche de elemente <b>a</b> si <b>b</b>{" "}
          returnam un numar mai mare decat zero, atunci <b>b</b> este pus primul
          in array-ul rezultat.
        </li>
        <li>
          Daca returnam un numar mai mic decat zero, <b>a</b> este pus primul.
        </li>
        <li>
          Daca returnam zero, ordinea in care se afla <b>a</b> si <b>b</b>{" "}
          ramane asa cum a fost initial.
        </li>
      </ul>

      <br />
      <p>
        Daca nu specificam un callback, functia "sort" va presupune ca avem de-a
        face cu o lista de primitive si le va sorta ascendent. Putem folosi
        aceasta varianta daca avem un array de numere sau string-uri
        (string-urile sunt sortate alfabetic).
      </p>

      <p>
        Sintaxa:{" "}
        <Code
          inline
          code={`arr.sort((a, b) => {
  // execute something
});`}
        />
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod sort() ordoneaza alfabetic lista de nume - fara callback"
        defaultCode={`var people: string[] = ["John", "Billy", "Mark"];
var result: string[] = people.sort();
console.log('result:', result);`}
      />

      <CodeEditor
        stacked
        codeTitle="Exemplu cod sort() ordoneaza ascendent lista de persoane dupa varsta - cu callback"
        defaultCode={`var people: Array = [
  { name: "Mark", age: 10 }, 
  { name: "Billy", age: 45 }, 
  { name: "Chris", age: 25 }
];

var result: string[] = people.sort((a,b) => {
  if(a.age < b.age) {
    return -1;
  } else {
    return 1;
  }
});
console.log('result:', JSON.stringify(result));`}
      />
    </div>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
