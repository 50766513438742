import React from "react";
import { Divider, Typography } from "antd";
import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import { code1, code2, code3, code4 } from "./common";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      Cum putem exprima informatii de orice fel folosind concepte intelese de un
      computer.
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <>
      <LESSON_RO_ABSTRACT />
      <p>
        Daca ideea de baza a programarii este comunicarea, atunci avem nevoie de
        un sistem precis de exprimare, stocare si manipulare a informatiilor.
        Orice element din lumea reala, orice gand sau idee, are la baza
        informatii. In programare, informatiile poarta denumirea de date. Numele
        unei persoane, o lista de cumparaturi, pretul actiunilor la bursa, chiar
        si ADN-ul care descrie fiecare caracteristica a fiecarei persoane care a
        trait vreodata, toate sunt exemple de date.
      </p>
      <p>
        Pentru a putea folosi datele eficient, ele trebuie organizate,
        structurate. Pentru asta folosim <Notion label="tipuri de date" />.
        Fiecare limbaj are propriile variatii, dar toate au la baza urmatoarele
        tipuri de date fundamentale:
      </p>
      <ul>
        <li>
          <Notion label="boolean" />
        </li>
        <li>
          <Notion label="number" />
        </li>
        <li>
          <Notion label="string" />
        </li>
        <li>
          <Notion label="array" />
        </li>
        <li>
          <Notion label="object" />
        </li>
      </ul>
      <p>
        In TypeScript, avem alte 2 tipuri de date esentiale care reprezinta
        lipsa informatiilor, si despre care vom vorbi mai mult in lectiile
        urmatoare:
      </p>
      <ul>
        <li>
          <Notion label="null" />
        </li>
        <li>
          <Notion label="undefined" />
        </li>
      </ul>
      <p>
        Desi nu vom discuta prea curand despre ele, este bine de stiut ca
        limbajul TypeScript suporta si alte tipuri de date de baza:
      </p>
      <ul>
        <li>
          <Notion label="tuple" />
        </li>
        <li>
          <Notion label="enum" />
        </li>
        <li>
          <Notion label="unknown" />
        </li>
        <li>
          <Notion label="any" />
        </li>
        <li>
          <Notion label="void" />
        </li>
        <li>
          <Notion label="never" />
        </li>
      </ul>
      <p>In continuare, vom analiza pe rand fiecare tip esential de date.</p>
      <br />
      <Divider>
        <Typography.Title level={2}>boolean</Typography.Title>
      </Divider>
      <p>
        Cel mai simplu tip de date din TypeScript este tipul boolean. O
        variabila de acest tip are doar 2 valori posibile: <Term label="true" />{" "}
        sau <Term label="false" />.
      </p>
      <p>
        Dupa cum stim, computerele sunt niste masinarii digitale. Asta inseamna
        ca singurele valori pe care le inteleg cu adevarat sunt{" "}
        <Term label="0" /> si <Term label="1" />. <br />
        Conceptual vorbind, <Term label="0" /> poate fi interpretat ca{" "}
        <Term label="false" />, iar <Term label="1" /> ca fiind{" "}
        <Term label="true" />. Asta face ca tipul boolean sa fie una dintre cele
        mai importante notiuni din programare.
      </p>
      <p>
        Cu ajutorul unei valori de tip boolean, vom putea evalua expresii de
        forma "daca A este adevarat, atunci fa operatia B", care sunt prezente
        in aproape fiecare program scris vreodata.
      </p>
      <p>
        Numele "boolean" este dat in onoarea matematicianului din secolul 19
        numit <Notion label="George Boole" />. El este considerat parintele
        algebrei moderne si prin extensie, a Epocii Informationale.
      </p>
      <br />
      <br />
      <Divider>
        <Typography.Title level={2}>number</Typography.Title>
      </Divider>
      <p>
        In TypeScript, majoritatea numerelor, oricat de mari si cu oricate
        zecimale, sunt grupate in tipul <Notion label="number" />.
        <br />
        Un numar poate fi exprimat direct in cod, de exemplu{" "}
        <Term label="350" />,<Term label="56.7" />, sau poate fi rezultatul unei
        expresii matematice: <Term label="40 * 3" />
      </p>
      <br />
      <br />
      <Divider>
        <Typography.Title level={2}>string</Typography.Title>
      </Divider>
      <p>
        O alta parte fundamentala a unui program in TypeScript este lucratul cu
        date de tip text. Nume, descrieri si orice alt exemplu de informatii sub
        forma de text sunt considerate a fi de tip "string".
      </p>
      <p>
        Computerele interpreteaza un text ca fiind un sir de caractere. In limba
        engleza, cuvantul "sir" poate fi tradus ca "string". De aici,
        informatiile de tip text sunt "strings".
      </p>
      <p>
        Cand vrem sa scriem o valoare de tip string in TypeScript, trebuie sa o
        inconjuram cu ghilimele. Sunt permise atat ghilimelele simple, cat si
        cele duble. Singura regula este ca daca am pus ghilimele de un tip la
        inceputul string-ului, trebuie sa punem unele din acelasi fel la final.
      </p>
      Exemplu:
      {code1}
      <p>
        In TypeScript, variabilele de tip string au diferite functii si
        proprietati, despre care vom invata in curand. Ele ne permit sa facem
        lucruri cum ar fi sa aflam numarul de caractere, sa facem toate literele
        mici, sa facem prima litera a fiecarui cuvant sa fie mare, sau orice
        alta manipulare de text imaginabila.
      </p>
      <br />
      <Divider>
        <Typography.Title level={2}>array</Typography.Title>
      </Divider>
      <p>
        Tipul array mai este numit si lista. Folosim un array atunci cand vrem
        sa descriem o serie de elemente, cum ar fi o lista de cumparaturi, o
        lista de produse dintr-un stoc, o lista de persoane care fac parte
        dintr-o echipa.
      </p>
      <p>
        Tipurile de date descrise pana acum sunt numite tipuri primitive pentru
        ca valorile lor nu pot contine alt tip de date. De exemplu, un numar nu
        poate contine un string si nici invers. Array-urile si obiectele (despre
        care vom vorbi imediat) sunt tipuri complexe, sau non-primitive. Ele pot
        contine in interiorul lor alte tipuri de date. Daca un array reprezinta
        o lista de nume, de exemplu, atunci tipul fiecarui nume este un string.
        Daca reprezinta o serie de numere castigatoare la o loterie, atunci
        acele numere sunt de tipul... numar.
      </p>
      <Important label="Pe langa valori primitive, un array fi alcatuit dintr-o serie de alte array-uri sau obiecte. 😱" />
      <br />
      <p>
        Fiecare element dintr-un array are asociata o pozitie unica,{" "}
        <b>incepand de la zero</b>. Astfel, primul element are pozitia 0,
        urmatorul pozitia 1 si tot asa.
      </p>
      <p>
        In cod, array-urile sunt scrise folosind paranteze patrate, separand
        elementele din interior cu virgula, astfel:
      </p>
      {code2}
      <p>Daca am aveau un array alcatuit din alte array-uri, ar arata asa:</p>
      {code3}
      <p>
        Tipul array ne permite sa executam operatii de ordonare, filtrare,
        cautare si multe altele atunci cand avem de-a face cu liste, indiferent
        de natura lor.
      </p>
      <br />
      <Divider>
        <Typography.Title level={2}>object</Typography.Title>
      </Divider>
      <p>
        Daca un array reprezinta o serie in care fiecare element are asociata o
        pozitie unica, un obiect reprezinta o colectie neordonata de date.
      </p>
      <p>
        Un obiect este compus din proprietati. Fiecare proprietate are un nume
        si o valoare. Valorile proprietatilor au propriul tip de date. Asta
        inseamna ca intr-un obiect putem avea in acelasi timp numere,
        string-uri, valori de tip boolean, array-uri, inclusiv alte obiecte.
      </p>
      <p>
        In cod, obiectele se noteaza folosind acolade, iar proprietatile sunt
        separate de virgula, ca in cazul array-urilor. Intre numele unei
        proprietati si valoarea ei se pun 2 puncte.
      </p>
      <p>Un exemplu de obiect ar putea arata astfel:</p>
      {code4}
    </>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
