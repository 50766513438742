import React from "react";
import { Divider, Typography } from "antd";
// import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
// import * as common from "./common";
import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";
import translate from "../../common/translate";

function LESSON_RO_ABSTRACT() {
  return <p>Daca a este adevarat, atunci fa lucrul x.</p>;
}

function LESSON_RO_PAGE() {
  return (
    <>
      <LESSON_RO_ABSTRACT />

      <p>
        Indiferent daca o facem constient sau nu, fiecare om isi bazeaza deciziile pe o serie nesfarsita de conditii.
        "Daca vremea este calda, voi face o plimbare", "Daca am suficienti bani, voi manca in oras", "Daca ma simt
        obosit, ma culc. Altfel, ma voi uita la un film". Prin urmare, programele pe care le scriem urmeaza acelasi
        model, iar asta face conditiile un element aproape indispensabil al oricarei aplicatii.
      </p>

      <p>
        In TypeScript, avem 2 cuvinte cheie pentru conditii: <Term label="if" /> (traducerea pentru "daca" in limba
        engleza) si <Term label="else" /> (traducerea pentru "altfel" in limba engleza). Ca si la bucle, prima parte a
        conditiei este imbracata in paranteze rotunde, apoi corpul este imbracat in acolade. Intre acolada de deschidere
        si cea de inchidere putem pune orice alte instructiuni, iar ele vor fi executate doar daca rezultatul conditiei
        este adevarat.
      </p>
      <p>O structura conditionala poate avea una sau mai multe ramuri:</p>
      <ul>
        <li>
          Daca a este adevarat, atunci fa x.{" "}
          <Code
            code={`if (a === true) {
  console.log('a is true');
}`}
          />
        </li>
        <li>
          Daca a este adevarat, atunci fa x. Altfel, fa y.{" "}
          <Code
            code={`if (a === true) {
  console.log('a is true');
} else {
  console.log('a is false');
}`}
          />
        </li>
        <li>
          Daca a este adevarat, atunci fa x. Altfel daca b este adevarat, fa y. Altfel, fa z.
          <Code
            code={`if (a === true) {
  console.log("a is true");
} else if (b === true){
  console.log("b is true");
} else if (c === true){
  console.log("c is true");
} else {
  console.log("nothing is true");
}`}
          />
        </li>
      </ul>
      <p>
        Prin faptul ca putem combina (si repeta) elementele unei structuri conditionale, putem sa exprimam orice fir
        logic.
      </p>
      <Important label="Atunci cand avem mai multe ramuri ale unei conditii, computerul le interpreteaza doar pana intalneste una al carui rezultat este adevarat. Daca computerul a gasit o astfel de ramura, restul sunt pur si simplu ignorate." />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2}>Operatori</Typography.Title>
      </Divider>
      <p>
        Pentru a putea implementa aceste exemple in TypeScript, trebuie mai intai sa discutam despre ceea ce se numesc
        operatori. Ei pot fi de mai multe tipuri, dar momentan vom discuta doar despre operatori aritmetici, logici sau
        de comparatie.
      </p>
      <ul>
        <li>
          Operatorii aritmetici sunt:
          <br />
          <br />
          <ul>
            <li>
              <Term label="+" /> (plus): a + b
            </li>
            <li>
              <Term label="-" /> (minus): a - b
            </li>

            <li>
              <Term label="*" /> (inmultit): a * b
            </li>

            <li>
              <Term label="/" /> (impartit): a / b
            </li>
            <li>
              <Term label="%" /> (restul impartirii): a % b - exemplu: 9 % 3 este 0
            </li>
            <li>
              <Term label="++" /> (adunare cu o unitate, numit si incrementare): a++
            </li>
            <li>
              <Term label="--" /> (scadere cu o unitate, numit si decrementare): a--
            </li>
          </ul>
        </li>
        <li>
          Operatorii de comparatie sunt la randul lor foarte des intalniti in matematica:
          <br />
          <br />
          <ul>
            <li>
              <Term label=">" /> (mai mare)
            </li>
            <li>
              <Term label="<" /> (mai mic)
            </li>
            <li>
              <Term label="===" /> (egal - vom vorbi curand despre motivul pentru care sunt 3 semne de egal)
            </li>
          </ul>
        </li>
        <li>
          Operatorii logici sunt ceva ce folosim in mod automat cand gandim, dar aproape niciodata nu scriem:
          <br />
          <br />
          <ul>
            <li>
              <Term label="&&" /> - AND (si) - rezultatul este adevarat daca toate partile sunt adevarate.
            </li>
            <li>
              <Term label="||" /> - OR (sau) - rezultatul este adevarat daca macar una dintre parti este adevarata.
            </li>
            <li>
              <Term label="!" /> - NOT (nu/opus) - daca valoarea initiala este adevarat, rezultatul este fals. Daca
              valoarea initiala este fals, rezultatul este adevarat.
            </li>
          </ul>
        </li>
      </ul>

      <br />
      <p>
        Un exemplu care incorporeaza cateva din elementele prezentate pana acum arata astfel:
        <br />
        <i>(incearca sa modifici codul astfel incat consola sa afiseze "success")</i>
      </p>
      <CodeEditor
        defaultCode={`var a:number = 3;

if (a + 5 === 10) {
  console.log("success");
} else {
  console.log("fail");
}`}
      />
      <p>
        In acest exemplu, folosim operatorul de adunare si pe cel de egalitate. Daca a + 5 este egal cu 10, executam
        codul din corpul primei conditii, care va afisa "success" in consola. Daca in schimb a + 5 nu este egal cu 10,
        se executa codul conditiei alternative, care afiseaza in consola "fail".
      </p>

      <br />
      <br />
      <p>Folosindu-ne de si mai multi operatori, putem avea situatii mai complexe:</p>
      <CodeEditor
        defaultCode={`var a:number = 3; 
var b:number = 7;

if (a === 7 || b === 3) {
  console.log("reverse");
} else if (a + b === 10){
  console.log("equals 10");
} else {
  console.log("boooo");
}`}
      />
      <p>Sa analizam codul exemplului de mai sus:</p>
      <ol>
        <li>
          Conditia initiala verifica daca a este egal (operatorul ===) cu 7 sau (operatorul ||) b este egal cu 3.
          Aceasta conditie nu are un rezultat adevarat, intrucat a are valoarea 3, iar b are valoarea 7 (deci inversul
          valorilor din conditie). Asta inseamna ca nu se va executa codul din interiorul acelei conditii, iar
          computerul verifica urmatoarea conditie.
        </li>
        <li>
          Verificam daca rezultatul adunarii lui a si b este 10. Suma este intr-adevar 10, deci rezultatul acestei
          ramuri este adevarat. Asta face sa fie executat codul din corpul celei de-a doua ramuri, iar in consola apare
          mesajul "equals 10".
        </li>
        <li>
          Din moment ce am avut deja un caz adevarat, nu mai interpretam codul din ultima ramura, ci pur si simplu
          trecem mai departe. In cazul nostru, asta inseamna ca se termina executia programului.
        </li>
      </ol>
      <i>
        Incearca sa modifici codul de mai sus astfel sa poti obtine, pe rand, fiecare dintre cele 3 mesaje in consola.
      </i>

      <br />
      <br />
      <Exercise
        label={`${translate("Exercise")} 1 - "Fizz Buzz"`}
        requirement={
          <div>
            Acum avem suficiente notiuni teoretice incat sa abordam o problema simpla si faimoasa in lumea
            programatorilor, dar care este si intalnita foarte des la interviuri, numita "Fizz Buzz". Cerinta ei este
            astfel:
            <br />
            <br />
            Afiseaza numerele de la 0 la 100 inclusiv, cate unul pe fiecare rand, cu urmatoarele exceptii:
            <ul>
              <li>In loc de numerele divizibile cu 3, afiseaza "fizz"</li>
              <li>In loc de numerele divizibile cu 5, afiseaza "buzz"</li>
              <li>In loc de numerele divizibile cu 15, afiseaza "fizzbuzz"</li>
            </ul>
            Asta inseamna ca mesajele din consola ar trebui sa arate astfel:
            <br />
            <i>
              <br />
              fizzbuzz
              <br />
              1<br />
              2<br />
              fizz
              <br />
              4<br />
              buzz
              <br />
              fizz
              <br />
              7<br />
              8<br />
              fizz
              <br />
              buzz
              <br />
              11
              <br />
              fizz
              <br />
              13
              <br />
              14
              <br />
              fizzbuzz
              <br />
              16
              <br />
              17
              <br />
              fizz
              <br />
            </i>
            <br />
            ... si tot asa pana la 100 (care ar trebui sa apara ca "buzz", nu ca "100")
          </div>
        }
        defaultCode={``}
        expectedCode={[
          `for (var i:number = 0; i <= 100; i++) {
  if(i % 15 === 0) {
    console.log("fizzbuzz");
  } else if (i % 5 === 0) {
    console.log("buzz");
  } else if (i % 3 === 0) {
    console.log("fizz");
  } else {
    console.log(i);
  }
}`,
        ]}
      />
    </>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
