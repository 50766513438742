import React from "react";
import { withRouter } from "react-router-dom";

import translate from "../../common/translate";
import lessonList from "../lessonList";
import Card from "../../Card/Card";
import "./LessonsPage.scss";

export class LessonsPage extends React.Component {
  render() {
    const { language, history } = this.props;

    const lessons = lessonList.map((lesson, i) => (
      <React.Fragment key={i}>
        {lesson.displayDividerBefore ? <div className="lesson-divider"></div> : null}
        <Card
          key={`lesson-${lesson.slug}`}
          title={`${lesson.index || i + 1}. ${translate(lesson.title)}`}
          className="lesson-card"
          onClick={() => history.push(`/lesson/${lesson.slug}`)}
        >
          {lesson.content[language].abstract}
        </Card>
      </React.Fragment>
    ));

    return <div className="lessons-page">{lessons}</div>;
  }
}

export default withRouter(LessonsPage);
