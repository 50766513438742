import React from "react";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import "./Important.scss";

export default function Important({ label }) {
  return (
    <span className="important">
      <ExclamationCircleOutlined />{" "}
      <span className="important-label">{label}</span>
    </span>
  );
}
