import React from "react";
import Code from "../../Code/Code";
// import Notion from "../../Notion/Notion";

export const code1 = (
  <Code>{`"ghilimele duble"
'ghilimele simple'`}</Code>
);

export const code2 = <Code>{`["a", "b", "c"]`}</Code>;
export const code3 = (
  <Code>{`[
  ["a", "b"],
  ["c", "d"],
  ["e", "f"],
]`}</Code>
);
export const code4 = (
  <Code>{`{
  numberProperty: 2,
  stringProperty: "a",
  booleanProperty: true,
  arrayProperty: ["first", "name"],
  objectProperty: {
    a: "b",
    c: "d"
  }
}`}</Code>
);
