import React from "react";
import Code from "../../Code/Code";
import Notion from "../../Notion/Notion";

export const code1 = (
  <Code language="graphql">
    {`type Recipe {
  id: ID!
  name: String!
  description: String
  photoUrl: String
  ingredients: [Ingredient]
}

type Ingredient {
  id: ID!
  name: String!
}`}
  </Code>
);

export const code2 = (
  <Code language="graphql">
    {`query ListRecipes {  
  recipes {
    id
    name
    description
    photoUrl
    ingredients
  }
}

query GetSingleRecipe($id: ID!) {
  recipe(input: $input) {
    id
    name
    description
    photoUrl
    ingredients
  }
}`}
  </Code>
);

export const code3 = (
  <Code language="graphql">
    {`mutation CreateRecipe(
  $input: CreateRecipeInput!
) {
  createRecipe(input: $input) {
    id
    name
    description
    photoUrl
    ingredients
  }
}`}
  </Code>
);

export const code4 = (
  <Code language="graphql">{`input CreateRecipeInput {
  name: String!
  description: String
  photoUrl: String
  ingredients: [Ingredient]
}`}</Code>
);

export const code5 = (
  <Code language="graphql">
    {`type Recipe {
  id: ID!
  name: String!
  description: String
  photoUrl: String
  ingredients: [Ingredient]
}

type Ingredient {
  id: ID!
  name: String!
}

query ListRecipes {
  recipes {
    id
    name
    description
    photoUrl
    ingredients
  }
}

query GetSingleRecipe($id: ID!) {
  recipe(input: $input) {
    id
    name
    description
    photoUrl
    ingredients
  }
}

input CreateRecipeInput {
  name: String!
  description: String
  photoUrl: String
  ingredients: [Ingredient]
}`}
  </Code>
);

export const scalarTypes = (
  <ul>
    <li>
      <Notion label="Int" />
    </li>
    <li>
      <Notion label="Float" />
    </li>
    <li>
      <Notion label="Boolean" />
    </li>
    <li>
      <Notion label="String" />
    </li>
    <li>
      <Notion label="ID" />
    </li>
  </ul>
);
