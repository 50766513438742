const translations = {
  Lessons: {
    ro: "Lectii",
    en: "Lessons",
  },
  "Data Types": {
    ro: "Tipuri de date",
    en: "Data Types",
  },
  Variables: {
    ro: "Variabile",
    en: "Variables",
  },
  Operators: {
    ro: "Operatori",
    en: "Operators",
  },
  Comments: {
    ro: "Comentarii",
    en: "Comments",
  },
  Console: {
    ro: "Consola",
    en: "Console",
  },
  Loops: {
    ro: "Bucle",
    en: "Loops",
  },
  Loops1: {
    ro: "Bucle - partea 1",
    en: "Loops - part 1",
  },
  Loops2: {
    ro: "Bucle - partea 2",
    en: "Loops - part 2",
  },
  Conditions: {
    ro: "Conditii",
    en: "Conditions",
  },
  Conditionals: {
    ro: "Conditionale",
    en: "Conditionals",
  },
  Functions: {
    ro: "Functii",
    en: "Functions",
  },
  Array: {
    ro: "Array",
    en: "Array",
  },
  String: {
    ro: "String",
    en: "String",
  },
  "Array Methods": {
    ro: "Array - metode",
    en: "Array Methods",
  },
  "Exercises Array Methods": {
    ro: "Exercitii pentru metodele array-urilor",
    en: "Execises for Array Methods",
  },

  GraphQL: {
    ro: "GraphQL",
    en: "GraphQL",
  },
  Intro: {
    ro: "Intro",
    en: "Intro",
  },
  "Ant Design": {
    ro: "Ant Design",
    en: "Ant Design",
  },
  Code: {
    ro: "Cod",
    en: "Code",
  },
  IsExecuting: {
    ro: "Se executa codul...",
    en: "Code is executing...",
  },
  CodeExecutionTimeout: {
    ro: "Executia a durat prea mult (probabil o bucla infinita).",
    en: "Execution took too long (probably an infinite loop).",
  },
  PositiveExerciseFeedback: {
    ro: "Felicitari, este corect!",
    en: "Congratulations, that is correct!",
  },
  NegativeExerciseFeedback: {
    ro: "Hmm, nu arata corect.",
    en: "Hmm, that doesn't look right.",
  },
  LiteralLogExerciseFeedback: {
    ro:
      "Scopul este sa lasam computerul sa calculeze valoarea variabilei, nu sa o scriem noi iarasi, manual.",
    en:
      "The idea is to let the computer evaluate the value of the variable, not for us to manually write it.",
  },
  ErrorExerciseFeedback: {
    ro: "Codul are erori.",
    en: "The code has errors.",
  },
  Requirement: {
    ro: "Cerinta",
    en: "Requirement",
  },
  Exercise: {
    ro: "Exercitiu",
    en: "Exercise",
  },
  SubmitExercise: {
    ro: "Verifica",
    en: "Submit",
  },
  ShowSolution: {
    ro: "Afiseaza solutia",
    en: "Show Solution",
  },
  HideSolution: {
    ro: "Ascunde solutia",
    en: "Hide Solution",
  },
  Solution: {
    ro: "Solutie",
    en: "Solution",
  },
};

export default translations;
