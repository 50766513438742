import React from "react";
import { Divider, Typography } from "antd";
// import Notion from "../../Notion/Notion";
// import Term from "../../Term/Term";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
import * as common from "./common";
import Code from "../../Code/Code";
import translate from "../../common/translate";
// import CodeEditor from "../../CodeEditor/CodeEditor";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      Consola ne permite sa afisam in orice moment valoarea unei variabile sau rezultatul unei expresii si prin urmare,
      reprezinta un element absolut esential in programare.
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <>
      <LESSON_RO_ABSTRACT />

      <p>
        TypeScript poate fi executat atat intr-un browser, cat si pe un server (vom avea mai multe lectii despre
        diferentele dintre cele 2). Pentru moment, ne intereseaza ideea de "consola".
      </p>
      <Divider>
        <Typography.Title level={2}>Ce este consola?</Typography.Title>
      </Divider>

      <p>
        Cand executam un program, ii cerem computerului sa execute un set de instructiuni. Aceste instructiuni sunt
        executate extrem de repede (aproximativ 2 miliarde pe secunda). Asta inseamna ca nu putem urmari executia unui
        program asa cum am urmari un film. Prin urmare, este util sa avem un mod in care sa putem afla starea in care se
        afla un program intr-un anumit moment. Consola este perfecta fix pentru asta: ne permite sa facem o "fotografie"
        a unei parti a programului, si astfel sa afisam valoarea oricarei variabile intr-un anumit moment, ales de noi.
        Programatorii folosesc aceasta functionalitate tot timpul, indiferent de limbajul de programare sau nivelul de
        experienta.
      </p>

      <p>
        Dupa ce o informatie a fost afisata in consola, ea ramane acolo. Urmatoarea data cand afisam ceva, acel lucru va
        fi afisat in continuare, si tot asa. Astfel, putem trata consola ca pe un jurnal: o data scris un rand, nu mai
        poate fi sters. Dar ca la un jurnal, putem sa rupem pagina si sa o luam de la capat. In limba engleza, cuvantul
        "jurnal" este tradus ca "log". Prin urmare, fiecare rand afisat in consola poarta in programare definirea de
        "log", cu pluralul "logs".
      </p>

      <p>
        In TypeScript, pentru a afisa ceva in consola, folosim o functie predefinita, numita{" "}
        <Code code="console.log" inline />. Nu am studiat inca functiile (vom explica in detaliu ceea ce sunt si cum se
        folosesc putin mai tarziu), dar pentru moment, este suficient sa stim sa folosim aceasta functie.
      </p>

      <p>
        Ca exemplu, putem declara o variabila, iar apoi folosindu-ne de <Code code="console.log" inline />, ii vom afisa
        valoarea.
      </p>
      <p>
        <i>
          Exemplele urmatoare sunt interactive, deci poti modifica oricare dintre ele pentru a vedea ce se intampla.
        </i>
      </p>
      {common.code1}

      <Important label="Fiecare log va aparea pe propriul rand." />
      <br />

      <p>Putem folosi aceasta functie de oricate ori, de exemplu:</p>
      {common.code2}

      <Important label="Orice expresie punem intre parantezele functiei console.log va fi evaluata de catre computer, iar in consola ne va aparea rezultatul." />

      {common.code3}
      <p>
        Dat fiind faptul ca intr-un program real, putem avea o lista destul de lunga de log-uri in consola,
        programatorii obisnuiesc sa puna cate o "eticheta" fiecarui log pe care il afiseaza, pentru a sti de unde a
        venit. Cu alte cuvinte, daca afisam valoarea variabilei "myName", am putea sa scriem doar atat:
      </p>
      {common.code4}
      <p>
        Problema este ca daca acest log ar fi unul dintre cateva zeci, cum am putea sti de unde a venit valoarea "Mike"?
        Prin urmare, ar fi mai bine sa adaugam o eticheta log-ului, astfel:
      </p>
      {common.code5}

      <p>
        Un lucru care poate parea minor, dar este important, este faptul ca e o idee buna sa lasam un spatiu inainte de
        ghilimelele de inchidere ale string-ului care reprezinta eticheta log-ului. Acest spatiu va fi afisat in log, si
        il va face mai usor de citit. Ca exemplu, cum arata cu si fara spatiu:
      </p>

      {common.code6}

      <Exercise
        label={`${translate("Exercise")} 1`}
        defaultCode={`var a:number = 17;
`}
        solution={[
          `var a:number = 17;
console.log(a);`,
        ]}
        wrongSolution={[
          {
            code: `console.log(17)`,
            problems: [translate("LiteralLogExerciseFeedback")],
          },
        ]}
        expectedLog="17"
        requirement={
          <>
            <span>Afiseaza valoarea variabilei "a"</span>
          </>
        }
      />
      <Exercise
        label={`${translate("Exercise")} 2`}
        defaultCode={`var favoriteColor:string = "blue";
`}
        solution={[
          `var favoriteColor:string = "blue";
console.log("favoriteColor: " + favoriteColor);`,
          `var favoriteColor:string = "blue";
console.log("favoriteColor:", favoriteColor);`,
        ]}
        wrongSolution={[
          {
            code: `console.log("favoriteColor: blue")`,
            problems: [translate("LiteralLogExerciseFeedback")],
          },
        ]}
        expectedLog="favoriteColor: blue"
        requirement={
          <>
            <span>
              Afiseaza valoarea variabilei "favoriteColor", impreuna cu o eticheta care sa arate de unde a venit log-ul
            </span>
          </>
        }
      />
    </>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
