import React from "react";
import { Divider, Typography } from "antd";
// import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
import * as common from "./common";
import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";
import translate from "../../common/translate";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      Buclele (numite si structuri repetitive) reprezinta un mod de a executa acelasi cod de mai multe ori. <br />
      Un exemplu simplu ar fi "fa operatia X de Y ori".
      <br />O iteratie a buclei poarta denumirea alternativa de "iteratie" (care este cel mai des folosita de
      programatori).
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <>
      <LESSON_RO_ABSTRACT />

      <p>
        Exista mai multe tipuri de bucle in typescript, dar pentru moment vom discuta despre 2 tipuri:{" "}
        <Term label="for" /> si <Term label="while" />.
      </p>
      <Divider>
        <Typography.Title level={2}>for</Typography.Title>
      </Divider>
      <p>Sintaxa pentru o bucla de tipul "for" arata astfel:</p>
      {common.code1}
      <p>
        Atunci cand creem o bucla de tip "for", avem intotdeauna cuvantul cheie "for", urmat de o paranteza in care
        exista 3 elemente, separate cu punct si virgula:
      </p>
      <ul>
        <li>
          <Code code="var i:number = 0" inline /> - <Term label="variabila contor" /> (conventia este sa o numim "i") -
          aceasta variabila stocheaza de cate ori s-a executat bucla:
        </li>
        <li>
          <Code code="i < 10" inline /> - <Term label="testul" /> - o conditie care este evaluata de fiecare data cand
          bucla se executa. In exemplul nostru, la fiecare iteratie a buclei, verificam daca valoarea lui "i" este mai
          mic decat 10. Daca da, atunci executam bucla. Daca nu, ne oprim.
        </li>
        <li>
          <Code code="i++" inline /> - <Term label="pasul" /> - dupa fiecare iteratie, cu cate unitati creste contorul.
          "++" este o forma prescurtata care inseamna ca "i" creste cu 1. Daca ar fi fost "i--", ar fi insemnat ca la
          fiecare iteratie a buclei, i ar fi scazut cu 1.
        </li>
      </ul>
      <Important label="Variabila contor poate sa se modifice in orice fel intre iteratii ale buclei. Poate fi i++, i--, i = i * 2 si orice alta expresie." />
      <br />
      <p>
        Dupa paranteza, avem un set de acolade. Desi am invatat ca acoladele sunt folosite pentru a declara obiecte,
        asta nu este tot. Ele sunt folosite si pentru a defini inceputul si sfarsitul "corpului" mai multor
        instructiuni, una dintre ele fiind buclele. Astfel, acolada deschisa marcheaza inceputul corpului buclei, iar
        cea inchisa marcheaza sfarsitul corpului buclei. Intre cele 2 acolade, putem adauga oricate linii de cod, iar
        ele vor fi executate de atatea ori cat am specificat in declaratia buclei.
      </p>
      <Important label="In TypeScript, de fiecare data cand avem acolade, codul dintre acolada de inchidere si de deschidere ar trebui indentat un nivel (folosin tasta Tab sau 2 spatii)." />
      <br />

      <br />
      <Typography.Title level={3}>
        Exemplu: afisarea valorii lui <b>i</b>
      </Typography.Title>
      {common.code2}
      <Important label="Este esential sa urmarim codul si sa il executam in mintea noastra, in acelasi mod in care o face un computer." />
      <br />
      <p>Acum, sa analizam ce se intampla in codul de mai sus.</p>
      <ol>
        <li>Se initializeaza bucla: declaram variabila "i" de tip "number", cu valoarea 0</li>
        <li>
          Verificam daca i este mai mic decat 10. Din moment ce i este 0, conditia este satisfacuta, deci continuam.
        </li>
        <li>Ignoram "i++" - aceasta instructiune se executa abia incepand cu a doua iteratie a buclei.</li>
        <li>Intalnim acolada de deschidere, ceea ce inseamna ca de aici inainte incepe codul care va fi repetat.</li>
        <li>
          Executam codul din interiorul buclei: <Code inline code="console.log(i);" /> - asta afiseaza in consola
          valoarea curenta a lui i, care este: 0.
        </li>
        <li>
          Ajungem la finalul buclei, reprezentat de acolada de inchidere. Fiind intr-o bucla, asta inseamna ca trebuie
          sa repetam executia
        </li>
        <li>Incepand cu a doua iteratie, ignoram instructiunea de initializare, adica declararea variabilei contor.</li>
        <li>
          Acum mergem direct la instructiunea care da pasul contorului: "i++". Asta face ca noua valoare a lui i sa fie
          1.
        </li>
        <li>
          Ne intoarcem la partea buclei care contine testul si verificam daca valoarea curenta a lui i este mai mica
          decat 10. Dat fiind faptul ca i este acum 1, conditia este satisfacuta, deci continuam.
        </li>
        <li>
          Executam codul din interiorul buclei: <Code inline code="console.log(i);" /> - asta afiseaza in consola
          valoarea curenta a lui i, care este: 1.
        </li>
        <br />
        <br />
        <li>
          <b>
            ... dupa cum am spus, o bucla reprezinta repetarea acelorasi instructiuni de mai multe ori. Vom continua
            pasii 2 -{">"} 10 pana valoarea lui i ajunge la 9.
          </b>
        </li>
        <br />
        <br />
        <li>
          Cand i are valoarea 9, dupa ce am executat codul din corpul buclei, ii crestem valoarea lui i cu o unitate si
          obtinem valoarea 10.
        </li>
        <li>
          Acum urmeaza iarasi testul: de data asta, 10 nu este mai mic decat 10, prin urmare ne oprim si iesim din
          bucla.
        </li>
      </ol>

      <br />
      <br />
      <Typography.Title level={3}>Exemplu: afisarea tuturor elementelor dintr-un array</Typography.Title>
      <br />
      <p>
        De cele mai multe ori, buclele sunt folosite pentru a accesa pe rand fiecare element dintr-un array. Dat fiind
        faptul ca un array reprezinta o serie de elemente numerotate crescator si avand in minte exemplul anterior,
        observam ca cele 2 notiuni sa potrivesc perfect: buclele si array-urile.
      </p>
      <CodeEditor
        stacked
        defaultCode={`var letters:string[] = ["a", "b", "c", "d"];\n\nfor(var i:number = 0; i < letters.length; i++){\n  console.log(letters[i]); // afisam valoarea elementului curent din array\n}`}
      />
      <p>
        Desi acest exemplu poate parea foarte complex, el este de fapt aproape acelasi lucru cu primul, cu 2 diferente:
      </p>
      <ol>
        <li>
          testul buclei este <Code inline code="i < letters.length" /> si nu <Code inline code="i < 10" />
        </li>
        <li>
          codul din interiorul buclei afiseaza valoarea unui element din array-ul numit "letters", <br />
          in loc sa afiseze direct valoarea lui i:
          <Code inline code="console.log(letters[i]);" />
        </li>
      </ol>
      <p>In continuare vom explica rolul celor 2 diferente:</p>
      <ol>
        <li>
          Fiecare array are o proprietate numita "length" (traducerea din limba engleza pentru cuvantul "lungime"). Ea
          ne poate spune in orice moment care este lungimea array-ului respectiv. Da, in cazul nostru, am fi putut avea
          doar <Code inline code="i < 4" />, pentru ca stim ca array-ul nostru are doar 4 elemente, dar in programare,
          incercam sa gandim intr-un mod dinamic. Vom invata la lectia dedicata array-urilor ca ele nu au o lungime
          fixa, si ca putem adauga si sterge elemente pe parcursul executiei programului. <br />
          <br />
          Asta inseamna ca intr-un program mai complex, nu am avea neaparat cum sa stim care este lungimea array-ului in
          momentul in care incepem bucla. Cel mai bun mod de a rezolva aceasta problema este sa lasam computerul sa o
          calculeze. Cu alte cuvinte, bucla noastra spune "repeta executia buclei de atatea ori cate elemente sunt in
          array".
        </li>
        <li>
          <p>Codul din interiorul buclei foloseste urmatoarele notiuni pe care le-am invatat deja:</p>
          <ul>
            <li>faptul ca valoarea lui i este diferita pentru fiecare iteratie a buclei</li>
            <li>cum sa accesam valoarea unui anumit element dintr-un array</li>
            <li>
              faptul ca orice expresie am pune intre parantezele functiei "console.log" va fi executata, iar valoarea ei
              va fi afisata in consola, pe un rand separat
            </li>
          </ul>
          <p>Astfel, acea linie de cod are urmatorul rezultat:</p>
          <ul>
            <li> la prima iteratie a buclei, afiseaza elementul din array aflat la indexul 0, care este "a".</li>
            <li> la a doua iteratie a buclei, afiseaza elementul din array aflat la indexul 1, care este "b".</li>
            <li> la a treia iteratie a buclei, afiseaza elementul din array aflat la indexul 2, care este "c".</li>
            <li> la a patra iteratie a buclei, afiseaza elementul din array aflat la indexul 3, care este "d".</li>
          </ul>
        </li>
      </ol>

      <Exercise
        label={`${translate("Exercise")} 1`}
        requirement={`Creeaza o bucla de tip "for" care sa se repete de 5 ori.`}
        defaultCode={``}
        expectedCode={[`for(var i:number = 0; i < 5; i++){}`]}
        wrongSolution={[
          { code: "console.log", problems: ["In acest execitiu, doar creem bucla, dar nu afisam nimic in consola."] },
        ]}
      />
      <Exercise
        label={`${translate("Exercise")} 2`}
        requirement={`Folosind o bucla de tip 'for', afiseaza numerele de la 0 la 19.`}
        defaultCode={``}
        expectedCode={[`for(var i:number = 0; i < 20; i++){\n  console.log(i);\n}`]}
        wrongSolution={[
          {
            code: "for(var i:number = 0; i < 19; i++)",
            problems: ["Daca vrem sa afisam numerele de la 0 la 19, trebuie ca verificarea sa fie daca i < 20."],
          },
        ]}
      />
      <Exercise
        label={`${translate("Exercise")} 3`}
        requirement={`Folosind o bucla de tip 'for', afiseaza numerele de la 15 la 30.`}
        defaultCode={``}
        expectedCode={[
          `for(var i:number = 15; i < 31; i++){\n  console.log(i);\n}`,
          `for(var i:number = 15; i <= 30; i++){\n  console.log(i);\n}`,
        ]}
      />
      <Exercise
        label={`${translate("Exercise")} 4`}
        requirement={`Folosind o bucla de tip 'for', afiseaza numerele de la 30 la 15, in ordine descrescatoare.`}
        defaultCode={``}
        expectedCode={[
          `for(var i:number = 30; i > 14; i--){\n  console.log(i);\n}`,
          `for(var i:number = 30; i >= 15; i--){\n  console.log(i);\n}`,
        ]}
      />
      <Exercise
        stacked
        label={`${translate("Exercise")} 5`}
        requirement={`Dat fiind array-ul "names", foloseste o bucla de tip "for" pentru a afisa toate elementele din array.`}
        defaultCode={`var names:string[] = ["John", "Emily", "Mike"];`}
        expectedCode={[
          `var names:string[] = ["John", "Emily", "Mike"];\nfor(var i:number = 0; i < names.length;i++) {\n  console.log(names[i]);\n}`,
        ]}
      />
    </>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
