import React from "react";
import { Space, Divider, Typography } from "antd";
import Term from "../../Term/Term";
import Notion from "../../Notion/Notion";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";

// import Code from "../../Code/Code";
import translate from "../../common/translate";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      Operatorii ne permit sa facem o multitudine de operatii pe date in
      programare: operatii aritmetice, comparatii, evaluari logice si multe
      altele.
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <>
      <LESSON_RO_ABSTRACT />
      <p>
        Exista mai multe tipuri de operatori decat cei despre care vor fi
        listati aici. Pentru o lista completa, poti merge{" "}
        <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Expressions_and_Operators">
          aici
        </a>
        . In aceasta lectie vom explora operatorii esentiali din TypeScript.
      </p>

      <p>Categoriile principale de operatori sunt:</p>
      <ul>
        <li>
          <a href="#math">Operatori aritmetici</a>
        </li>
        <li>
          <a href="#assignment">Operatori de atribuire</a>
        </li>
        <li>
          <a href="#comparison">Operatori de comparare</a>
        </li>
        <li>
          <a href="#string">Operatori pe string-uri</a>
        </li>
        <li>
          <a href="#logical">Operatori logici</a>
        </li>

        <li>
          <a href="#ternary">Operatorul conditional (ternar)</a>
        </li>
      </ul>

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="math">
          Operatori aritmetici
        </Typography.Title>
      </Divider>

      <table>
        <thead>
          <th style={{ width: 80 }}>Operator</th>
          <th style={{ width: 100 }}>Nume</th>
          <th>Descriere</th>
          <th>Exemplu</th>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              +
            </td>
            <td>Adunare</td>
            <td></td>
            <td>3 + 5 = 8</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              -
            </td>
            <td>Scadere</td>
            <td></td>
            <td>100 - 5 = 95</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              *
            </td>
            <td>Inmultire</td>
            <td></td>
            <td>3 * 5 = 15</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              /
            </td>
            <td>Impartire</td>
            <td>
              *rezultatul impartirii la zero este <Notion label="Infinity" />
            </td>
            <td>100 / 5 = 20</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              %
            </td>
            <td>Rest</td>
            <td>Calculeaza restul impartirii lui A la B. </td>
            <td>
              7 % 5 = 2 <br /> Pentru a afla daca x este par, evaluam daca x % 2
              === 0
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              ++
            </td>
            <td>Increment</td>
            <td>Aduna o unitate si salveaza rezultatul in variabila. </td>
            <td style={{ textAlign: "left" }}>
              <Code
                code={`var x:Number = 5;
x++;
console.log(x); // 6`}
              />
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              --
            </td>
            <td>Decrement</td>
            <td>Scade o unitate si salveaza rezultatul in variabila. </td>
            <td style={{ textAlign: "left" }}>
              <Code
                code={`var x:Number = 5;
x--;
console.log(x); // 4`}
              />
            </td>
          </tr>
        </tbody>
      </table>

      {/*/////////////////////////////////////////////////////////////////////*/}
      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="assignment">
          Operatori de atribuire
        </Typography.Title>
      </Divider>

      <table>
        <thead>
          <th>Operator</th>
          <th>Prescurtare</th>
          <th>Echivalent</th>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              =
            </td>
            <td>
              <Code inline code="x = y" />
            </td>
            <td>
              <Code inline code="x = y" />
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              +=
            </td>
            <td>
              <Code inline code="x += y" />
            </td>
            <td>
              <Code inline code="x = x + y" />
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              -=
            </td>
            <td>
              <Code inline code="x -= y" />
            </td>
            <td>
              <Code inline code="x = x - y" />
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              *=
            </td>
            <td>
              <Code inline code="x *= y" />
            </td>
            <td>
              <Code inline code="x = x * y" />
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              /=
            </td>
            <td>
              <Code inline code="x /= y" />
            </td>
            <td>
              <Code inline code="x = x / y" />
            </td>
          </tr>
        </tbody>
      </table>

      {/*/////////////////////////////////////////////////////////////////////*/}

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="comparison">
          Operatori de comparare
        </Typography.Title>
      </Divider>

      <table>
        <thead>
          <th style={{ width: 80 }}>Operator</th>
          <th style={{ width: 160 }}>Nume</th>
          <th>Descriere</th>
          <th>Exemplu care returneaza true</th>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              ==
            </td>
            <td>Egalitate</td>
            <td>
              Returneaza true daca valoare este egala, indiferent daca tipul
              este sau nu acelasi
            </td>
            <td>3 == "3"</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              ===
            </td>
            <td>Egalitate stricta</td>
            <td>
              Returneaza true daca valoare este egala, iar tipul este acelasi
            </td>
            <td>3 === 3</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              !=
            </td>
            <td>Inegalitate</td>
            <td>Returneaza true daca valoarea este diferita diferit</td>
            <td>3 != 5</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              !==
            </td>
            <td>Inegalitate stricta</td>
            <td>
              Returneaza true daca valoarea este diferita sau daca tipul este
              diferit
            </td>
            <td>3 !== "5"</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              {">"}
            </td>
            <td>Mai mare</td>
            <td>
              Returneaza true daca valoarea din stanga este mai mare decat cea
              din dreapta
            </td>
            <td>3 {">"} 2</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              {"<"}
            </td>
            <td>Mai mic</td>
            <td>
              Returneaza true daca valoarea din stanga este mai mica decat cea
              din dreapta
            </td>
            <td>3 {"<"} 7</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              {">="}
            </td>
            <td>Mai mare sau egal</td>
            <td>
              Returneaza true daca valoarea din stanga este mai mare decat cea
              din dreapta sau daca valorile sunt egale
            </td>
            <td>
              3 {">="} 2 <br />3 {">="} 3
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              {"<="}
            </td>
            <td>Mai mic sau egal</td>
            <td>
              Returneaza true daca valoarea din stanga este mai mica decat cea
              din dreapta sau daca valorile sunt egale
            </td>
            <td>
              {" "}
              3 {"<="} 5 <br />3 {"<="} 3
            </td>
          </tr>
        </tbody>
      </table>

      {/*/////////////////////////////////////////////////////////////////////*/}

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="string">
          Operatori pe string-uri
        </Typography.Title>
      </Divider>

      <table>
        <thead>
          <th style={{ width: 80 }}>Operator</th>
          <th style={{ width: 160 }}>Nume</th>
          <th>Descriere</th>
          <th>Exemplu</th>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              +
            </td>
            <td>Concatenare</td>
            <td>
              Returneaza un nou string rezultat din unirea (lipirea) celor 2
              valori
            </td>
            <td>"abc" + "def" = "abcdef"</td>
          </tr>
        </tbody>
      </table>

      {/*/////////////////////////////////////////////////////////////////////*/}

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="logical">
          Operatori logici
        </Typography.Title>
      </Divider>

      <table>
        <thead>
          <th style={{ width: 80 }}>Operator</th>
          <th style={{ width: 160 }}>Nume</th>
          <th>Descriere</th>
          <th>Exemplu</th>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              {"&&"}
            </td>
            <td>AND (si)</td>
            <td>Returneaza true daca ambele valori sunt truthy</td>
            <td style={{ textAlign: "left" }}>
              <br />
              <Code inline code={`console.log(true && true); // true`} />
              <br />
              <br />
              <Code inline code={`console.log(true && false); // false`} />
              <br />
              <br />
              <Code inline code={`console.log(false && false); // false`} />
              <br />
              <br />
              <Code inline code={`console.log(3 && 9); // true`} />
              <br />
              <br />
              <Code inline code={`console.log(0 && "a"); // false`} />
              <br />
              <br />
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              {"||"}
            </td>
            <td>OR (sau)</td>
            <td>Returneaza true daca cel putin una din valori este truthy</td>
            <td style={{ textAlign: "left" }}>
              <br />
              <Code inline code={`console.log(true || true); // true`} />
              <br />
              <br />
              <Code inline code={`console.log(true || false); // true`} />
              <br />
              <br />
              <Code inline code={`console.log(false || false); // false`} />
              <br />
              <br />
              <Code inline code={`console.log(3 || 9); // true`} />
              <br />
              <br />
              <Code inline code={`console.log(0 || "a"); // true`} />
              <br />
              <br />
              <Code inline code={`console.log(0 || false); // false`} />
              <br />
              <br />
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }} className="dark">
              {"!"}
            </td>
            <td>NOT (negare/opus)</td>
            <td>
              Returneaza opusul valorii logice a operandului (true daca valoarea
              este falsey si false daca valoarea este truthy
            </td>
            <td>
              <td style={{ textAlign: "left" }}>
                <br />
                <Code inline code={`console.log(!true); // false`} />
                <br />
                <br />
                <Code
                  inline
                  code={`var x:Number = 1;
console.log(!x); // false`}
                />
                <br />
                <br />
                <Code
                  inline
                  code={`var x:Number = 1;
console.log(!!x); // true`}
                />
                <br />
                <br />
                <Code
                  inline
                  code={`var x:String = "abcd";
console.log(!x); // false`}
                />
                <br />
                <br />
                <Code
                  inline
                  code={`var x:String = null;
console.log(!x); // true`}
                />
                <br />
                <br />
              </td>
            </td>
          </tr>
        </tbody>
      </table>

      {/*/////////////////////////////////////////////////////////////////////*/}

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="ternary">
          Operatorul ternar
        </Typography.Title>
      </Divider>

      <p>
        Operatorul ternar este singurul operator din TypeScript care accepta 3
        operanzi. Sintaxa este astfel:
      </p>
      <Code code={`condition ? value1 : value2`} />
      <p>
        Daca rezultatul evaluarii conditiei este truthy, atunci rezultatul
        intregii operatii este prima valoare (cea de dupa "?"). Altfel,
        rezultatul este a doua valoare (cea de dupa ":"). Operatorul ternar este
        o forma prescurtata a lui if/else.
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod operator ternar"
        defaultCode={`var a:Number = 10;
var b:Number = 14;
var name:String = a > b ? "Mary" : "Jane";

console.log(name);`}
      />
    </>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
