import React from "react";

import { Card as AntCard, Typography, Divider, Space } from "antd";

import cx from "classnames";

import "./Card.scss";

export default function Card({ children, className, title, actions, actionsPrefix, attributes, onClick }) {
  let titleElement = null;
  let actionsElement = null;
  let headerElement = null;
  let actionsPrefixElement = null;

  if (title) {
    titleElement = (
      <Typography.Title level={3} className="card-title">
        {title}
      </Typography.Title>
    );
  }

  if (actions) {
    actionsElement = <div className="card-actions">{actions}</div>;
  }
  if (actionsPrefix) {
    actionsPrefixElement = <div className="card-actions-prefix">{actionsPrefix}</div>;
  }
  if (titleElement || actionsElement) {
    headerElement = (
      <div className="card-header">
        <Space className="spread">
          {actionsPrefixElement}
          {titleElement || <div />}
          {actionsElement || <div />}
        </Space>
        <Divider />
      </div>
    );
  }

  return (
    <AntCard
      bordered={false}
      className={cx("card", { interactive: onClick }, className)}
      {...attributes}
      onClick={onClick}
    >
      {headerElement}
      {children}
    </AntCard>
  );
}
