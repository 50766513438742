import React from "react";
import { Divider, Typography, Row, Col } from "antd";
import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
// import * as common from "./common";
import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";
import translate from "../../common/translate";

import "./common.scss";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      Deja stim ca tipul string este cel care ne permite sa lucram cu informatii
      in format text in TypeScript. In aceasta lectie, exploram proprietatile si
      metodele tipului string.
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <div className="lesson-functions">
      <LESSON_RO_ABSTRACT />
      <p>
        Cateva dintre cele mai folosite operatii pe string-uri sunt: -
        verificarea lungimii (folosing proprietatea "length") - construirea unui
        string din bucati, folosing operatorii "+" si "+=" - verificarea
        existentei sau pozitiei unei bucati dintr-un string folosind metoda
        "indexOf()" - extragerea unor sectiuni dintr-un string folosind metoda
        "substring()"
      </p>

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="instantiation">
          Construirea unui string
        </Typography.Title>
      </Divider>

      <p>Putem construi un string in 2 moduri:</p>
      <ul>
        <li>
          prin scrierea literala a unui string:
          <br />
          <br />
          <ul>
            <li>
              <Code inline code="'I am a string'" /> - cu semnul "single quote"
            </li>
            <li>
              <Code inline code={`"I am a string"`} /> - cu semnul "single
              quote"
            </li>
            <li>
              <Code inline code={"`I am a string`"} /> - cu semnul "backtick"
              (semnul se afla de obicei in stanga jos pe tastatura, langa tasta
              Z, sau in stanga sus, langa tasta 1)
            </li>
          </ul>
        </li>
        <li>
          folosing functia constructor "String()"
          <br />
          <br />
          <Code inline code="var a: string = String('I am a string')" />
        </li>
      </ul>

      <p>
        Crearea unui string literal folosind quotemarks (ghilimele) simple sau
        duble este acelasi lucru, in schimb daca folosim backticks creem ceea ce
        se numeste un <Notion label="template literal" />. Un template literal
        este tot un string, doar ca ne permite sa folosim ceea ce se numeste{" "}
        <Notion label="interpolation" />, adica ne permite sa introducem
        valorile altor variabile sau functii in cadrul string-ului.
      </p>

      <p>
        Pentru a folosi interpolation, folosim semnul $, impreuna cu un set de
        acolade: {"${}"}. Codul pe care il scriem in interiorul acoladelor va fi
        evaluat ca si cod de TypeScript, iar valoarea rezultata va fi inserata
        in string.
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod interpolation"
        defaultCode={`var me: string = "John Doe";
var greeting = \`Hello \${me}\`;
console.log("greeting:", greeting);
`}
      />

      <p>
        Un alt avantaj pe care il are un template string este ca poate fi scris
        pe mai multe linii. In mod normal, string-urile nu permit sa fie scrise
        pe mai multe randuri. Putem totusi obtine acest efect cu in string
        normal in 2 moduri:
      </p>

      <CodeEditor
        stacked
        codeTitle="Exemplu cod 1 multi-line string - folosind concatenare"
        defaultCode={`var text: string = "some" +
" code" +
" here";
console.log(text);
`}
      />

      <CodeEditor
        stacked
        codeTitle='Exemplu cod 2 multi-line string - folosind caracterul backslash ("\" )'
        defaultCode={`var text: string = "some \\
code \\
here";
console.log(text);
`}
      />

      <CodeEditor
        stacked
        codeTitle="Exemplu cod 3 multi-line string - folosind template string"
        defaultCode={`var text: string = \`some
code
here\`;
console.log(text);
`}
      />

      <p>
        Dupa cum am vazut, folosirea unui template string pentru a scrie un
        string pe mai multe randuri este mai usor decat alternativele.
      </p>

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="properties">
          Proprietati ale unei instante de tip string
        </Typography.Title>
      </Divider>

      <p>
        O instanta a tipului string are o singura proprietate, numita "length",
        care este de tip numar si contine numarul de caractere ale string-ului.
      </p>
      <Important label="Orice caracter este numarat, nu doar literele. Asta include simboluri de orice fel, si in special spatiile goale." />
      <CodeEditor
        stacked
        codeTitle="Exemplu cod length"
        defaultCode={`var text: string = "I am learning TypeScript!";
console.log("number of characters:", text.length);`}
      />
      <p>
        Proprietatea "length" este foarte folosita in multe situatii. De
        exemplu, daca vrem sa gasim ultimul caracter al unui anumit string,
        putem face astfel:
      </p>
      <CodeEditor
        stacked
        codeTitle="Exemplu cod aflarea ultimului caracter"
        defaultCode={`var text: string = "I am learning TypeScript!";
console.log("last character:", text[text.length-1]);`}
      />

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="properties">
          Metode ale unei instante de tip string
        </Typography.Title>
      </Divider>

      <p>
        In aceasta sectiune vom studia pe rand fiecare dintre metodele esentiale
        oferite de tipul string (exista mai multe metode, dar noi le vom studia
        doar pe cele mai des folosite. Pe restul le poti gasi{" "}
        <b>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String"
          >
            aici
          </a>
        </b>
        ): numele metodei, ce parametri primeste, si ce valoarea returneaza. In
        acest mod este organizata intotdeauna documentatia unui limbaj, ceea ce
        inseamna ca urmarind lectia, vom invata atat cum sa folosim metodele
        string-urilor, dar si cum sa citim documentatie tehnica.
      </p>

      <br />
      <ul>
        <li>
          <Code inline code="charAt(index: Number)" />
          <p>
            Returneaza caracterul aflat la indexul specificat. Este echivalentul
            citirii unui caracter folosind paranteze patrate:"[]"
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod charAt()"
            defaultCode={`var text: string = "I am learning TypeScript!";
console.log("char at 6 with charAt():", text.charAt(6));
console.log("char at 6 with []:", text[6]);`}
          />
        </li>
        <li>
          <Code inline code="includes(searchString: string)" />
          <p>
            Este folosit pentru a afla daca un string contine o anumita secventa
            de caractere. Returneaza true daca secventa de caractere este gasita
            cel putin o data, altfel returneaza false.
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod includes()"
            defaultCode={`var text: string = "I am learning TypeScript!";
console.log("includes 'TypeScript':", text.includes('TypeScript'));
console.log("includes 'You':", text.includes('You'));`}
          />
        </li>
        <li>
          <Code inline code="startsWith(searchString: string)" />
          <p>
            Este folosit pentru a afla daca un string incepe cu o anumita
            secventa de caractere. Returneaza true daca secventa de caractere
            este gasita la inceputul string-ului, altfel returneaza false.
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod startsWith()"
            defaultCode={`var text: string = "I am learning TypeScript!";
console.log("starts with a:", text.startsWith('a'));
console.log("starts with I:", text.startsWith('I'));
console.log("starts with i:", text.startsWith('i'));`}
          />
        </li>
        <li>
          <Code inline code="endsWith(searchString: string)" />
          <p>
            Este folosit pentru a afla daca un string incepe cu o anumita
            secventa de caractere. Returneaza true daca secventa de caractere
            este gasita la finalul string-ului, altfel returneaza false.
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod endsWith()"
            defaultCode={`var text: string = "I am learning TypeScript!";
console.log("ends with a:", text.endsWith('a'));
console.log("ends with !:", text.endsWith('!'));`}
          />
        </li>
        <li>
          <Code inline code="indexOf(searchString: string)" />
          <p>
            Este folosit pentru a afla indexul la care se aflat o anumita
            secventa de caractere. Daca secventa este gasita cel putin o data,
            atunci metoda returneaza indexul la care a fost gasita prima
            ocurenta. Daca secventa nu este gasita, metoda returneaza valoarea
            -1.
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod indexOf()"
            defaultCode={`var text: string = "I am learning TypeScript!";
console.log("learning:", text.indexOf('learning'));
console.log("a:", text.indexOf('a'));
console.log("blabla:", text.indexOf('blabla'));`}
          />
        </li>
        <li>
          <Code inline code="split(separator: string)" />
          <p>
            Returneaza un array de string-uri dintr-un string, prin "taierea"
            string-ului original de fiecare data cand este intalnita o anumita
            secventa de caractere.
          </p>
          <p>
            De exemplu, putem creea un array de cuvinte atunci cand avem un
            string care contine o propozitie, daca apelam metoda "split" si ii
            dam ca separator un spatiu gol.
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod split()"
            defaultCode={`var text: string = "I am learning TypeScript!";
var words:Array = text.split(" ");
console.log("result:", JSON.stringify(words));
`}
          />
        </li>
        <li>
          <Code inline code="substring(indexStart: Number, indexEnd: Number)" />
          <p>
            Returneaza o parte dintr-un string, incepand de la caracterul aflat
            la "indexStart" inclusiv si pana la caracterul aflat la indexul
            "indexEnd" exclusiv.
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod substring()"
            defaultCode={`var text: string = "I am learning TypeScript!";
console.log("result:", text.substring(5,10));
`}
          />
        </li>
        <li>
          <Code inline code="toLowerCase()" />
          <p>
            Returneaza un string de aceeasi lungime cu string-ul original, doar
            ca fiecare litera este converita intr-o litera mica ("A" devine
            "a"). Caracterele care nu sunt litere raman neschimbate. Literele
            care erau deja mici raman neschimbate
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod toLowerCase()"
            defaultCode={`var text: string = "I am learning TypeScript!";
console.log("result:", text.toLowerCase());
`}
          />
        </li>
        <li>
          <Code inline code="toUpperCase()" />
          <p>
            Returneaza un string de aceeasi lungime cu string-ul original, doar
            ca fiecare litera este converita intr-o litera mare ("a" devine
            "A"). Caracterele care nu sunt litere raman neschimbate. Literele
            care erau deja mari raman neschimbate.
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod toUpperCase()"
            defaultCode={`var text: string = "I am learning TypeScript!";
console.log("result:", text.toUpperCase());
`}
          />
        </li>
        <li>
          <Code inline code="trim()" />
          <p>
            Returneaza un string cu aceleasi caractere din string-ul initial, cu
            exceptia caracterelor care reprezinta spatii goale si care se afla
            la inceputul si la sfarsitul string-ului.
          </p>
          <CodeEditor
            stacked
            codeTitle="Exemplu cod trim()"
            defaultCode={`var text: string = "       I am learning TypeScript!      ";
console.log("text:", text);
console.log("text with trim:", text.trim());
`}
          />
        </li>
      </ul>

      <br />
      <br />
      <Divider>
        <Typography.Title level={2} id="comparison">
          Comparatii
        </Typography.Title>
      </Divider>
      <p>
        Putem compara 2 valori de tip string si putem afirma ca una este mai
        mare decat cealalta. Desi operatorii sunt aceiasi ca atunci cand
        comparam numere, comparatia in sine se face in functie de ordinea
        alfabetica a caracterelor.
      </p>

      <CodeEditor
        stacked
        codeTitle="Comparatie string-uri, exemplu 1"
        defaultCode={`var a: string = 'a';
var b: string = 'b';

if (a < b) { // true
  console.log(a + ' is less than ' + b);
} else if (a > b) {
  console.log(a + ' is greater than ' + b);
} else {
  console.log(a + ' and ' + b + ' are equal.');
}
`}
      />

      <CodeEditor
        stacked
        codeTitle="Comparatie string-uri, exemplu 2"
        defaultCode={`var a: string = 'a';
var b: string = 'B';

if (a < b) { // true
  console.log(a + ' is less than ' + b);
} else if (a > b) {
  console.log(a + ' is greater than ' + b);
} else {
  console.log(a + ' and ' + b + ' are equal.');
}
`}
      />
      <p>
        Motivul pentru care "a" este mai mare decat "B" este pentru ca atunci
        cand facem comparatia, TypeScript converteste fiecare caracter intr-un
        numar conform codului <Notion label="ASCII" />, in care fiecare caracter
        are asociat o valoare numerica. De exemplu:
        <br />
        <br />
        <ul>
          <li>caracterul "A" are valoarea 65</li>
          <li>caracterul "B" are valoarea 66</li>
          <li>caracterul "a" are valoarea 97</li>
          <li>caracterul "b" are valoarea 98</li>
        </ul>
        Prin urmare, conform acestui algoritm, "a" este intr-adevar mai mare
        decat "B", dar "a" este mai mic decat "b".
      </p>
      <Important label='Pentru acest motiv, atunci cand comparam valori de tip string, de cele mai multe ori este o idee buna ca mai intai sa le convertim astfel incat ambele sa aiba doar litere mari sau doar litere mici (folosind metoda "toLowerCase()" sau "toUpperCase()"), iar atunci comparatia se va face dupa ordinea alfabetica asa cum ne asteptam.' />
    </div>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
