import React from "react";
// import Notion from "../../Notion/Notion";
// import Term from "../../Term/Term";
// import Important from "../../Important/Important";

function LESSON_EN_ABSTRACT() {
  return <p>What actually is programming? Why should we learn it, and how do we begin?</p>;
}

function LESSON_EN_PAGE() {
  return (
    <>
      <LESSON_EN_ABSTRACT />
      <i>This lesson does not have an English version yet, please check out the Romanian version for now.</i>
    </>
  );
}

const lesson = {
  abstract: <LESSON_EN_ABSTRACT />,
  page: <LESSON_EN_PAGE />,
};

export default lesson;
