import React from "react";
import cx from "classnames";
import Prism from "prismjs";
import "prismjs/components/prism-markup-templating";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-graphql";
// import "prismjs/themes/prism-okaidia.css";
import "prismjs/themes/prism-tomorrow.css";
import "./Code.scss";

export default function Code({ language = "ts", children, code, inline }) {
  let contentPrism = "";
  if (code) {
    contentPrism = Prism.highlight(code, Prism.languages[language], language);
  }
  if (children) {
    contentPrism = Prism.highlight(children, Prism.languages[language], language);
  }
  let content = null;
  if (contentPrism) {
    content = <div dangerouslySetInnerHTML={{ __html: contentPrism }} />;
  }

  return <div className={cx("code", { inline })}>{content}</div>;
}
