import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import cx from "classnames";
import cookie from "js-cookie";
import { Button } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

import translate from "../common/translate";

import LessonsPage from "../Lessons/LessonsPage/LessonsPage";
import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import MainBar from "../MainBar/MainBar";
// import Sidebar from "../Sidebar/Sidebar";
import LessonPage from "../LessonPage/LessonPage";
import lessonList from "../Lessons/lessonList";

import "antd/dist/antd.css";

import "./Generic.scss";
import "./App.scss";

export class App extends React.Component {
  state = {
    isLoading: true,
    shouldDisplayBackground: true,
    shouldDisplayBoxedLayout: true,
    language: "en",
    apiUser: {
      id: "xdragosy@gmail.com",
      firstName: "dragos",
      lastName: "iordachioaia",
    },
  };

  componentDidMount() {
    const language = cookie.get("language") || "en";
    window.language = language;
    this.setState({ isLoading: false, language });
  }

  setLanguage = (language) => {
    window.language = language;
    cookie.set("language", language);
    this.setState({ language });
  };

  setBackground = (value) => {
    this.setState({ shouldDisplayBackground: value });
  };

  setBoxedLayout = (value) => {
    this.setState({ shouldDisplayBoxedLayout: value });
  };

  setPageTitle = () => {};

  displayLessonRoutes = () => {
    const { language } = this.state;
    const { history } = this.props;

    return lessonList.map((lesson, i) => {
      return (
        <Route path={`/lesson/${lesson.slug}`} exact key={`route-${lesson.slug}`}>
          <LessonPage {...lesson} language={language} i={i} />
          <div className="page-navigation-buttons">
            {i > 0 ? (
              <Button
                type=""
                className="prev"
                disabled={i <= 0}
                onClick={() => history.push(`/lesson/${lessonList[i - 1]?.slug}`)}
              >
                <CaretLeftOutlined /> {lessonList[i - 1].index || i}. {translate(lessonList[i - 1]?.title)}
              </Button>
            ) : (
              <div />
            )}

            {i < lessonList.length - 1 ? (
              <Button
                type=""
                className="next"
                disabled={i >= lessonList.length - 1}
                onClick={() => history.push(`/lesson/${lessonList[i + 1]?.slug}`)}
              >
                {lessonList[i + 1].index || i + 2}. {translate(lessonList[i + 1]?.title)} <CaretRightOutlined />
              </Button>
            ) : (
              <div />
            )}
          </div>
        </Route>
      );
    });
  };
  render() {
    const { isLoading, language, shouldDisplayBackground, shouldDisplayBoxedLayout } = this.state;

    return (
      <LoadingWrapper
        className={cx("app", {
          "with-background": shouldDisplayBackground,
          "with-boxed-layout": shouldDisplayBoxedLayout,
        })}
        isLoading={isLoading}
        content={() => (
          <>
            <MainBar {...this.state} setLanguage={this.setLanguage} language={language} />
            <div className="page-content">
              {/* <Sidebar /> */}
              <div className="inner-content">
                <Switch>
                  <Route path="/" exact>
                    <LessonsPage setPageTitle={this.setPageTitle} language={language} />
                  </Route>
                  {this.displayLessonRoutes()}
                </Switch>
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

export default withRouter(App);
