import React from "react";
import { Divider } from "antd";
import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import { code1, code2, code3, code4, code5, scalarTypes } from "./common";

function LESSON_EN_ABSTRACT() {
  return (
    <p>
      Every application needs a data source. In the case of web apps, this is
      most often an <Notion label="API" />. There are multiple types of APIs,
      each with its own way of organising data. Most modern APIs use a
      technology called <Notion label="GraphQL" />, and we're going to learn why
      that's a great choice.
    </p>
  );
}

function LESSON_EN_PAGE() {
  return (
    <>
      <LESSON_EN_ABSTRACT />

      <p>
        <Notion label="GraphQL" /> offers a way to make requests to a server, in
        which we describe the type of data we want to get back. GraphQL only
        represents the API layer, and the data it sends back can come from any
        type of database: <Notion label="MySQL" />,{" "}
        <Notion label="PostgreSQL" />, <Notion label="DynamoDB" />,{" "}
        <Notion label="MongoDB" />, or anything else.
      </p>
      <Divider>
        <h2>Schema</h2>
      </Divider>
      <p>
        Every API which uses GraphQL is based on a description of all the pieces
        of information it can offer, called
        <Notion label="schema" />. The schema is written in a language called
        <Notion label="SDL" />, which is very similar to
        <Notion label="JSON" />. In this schema, we will define our data types
        and the operations which allow us to interact with them.
      </p>
      <h3>Data types</h3>
      <p>
        In any API, there is at least one type of data, but usually there are
        many. If we had an API which powers a digital cookbook,then one of its
        data type would be a <Term label="Recipe" />. Each data type we declare
        must have at least one property (also called a field). In our example,{" "}
        <Term label="Recipe" /> has 5 fields: <Term label="id" />,{" "}
        <Term label="name" />, <Term label="description" />
        , <Term label="photoUrl" /> and <Term label="ingredients" />.
      </p>

      {code1}

      <p>
        <Important
          label={
            <>
              Some properties have an exclamation mark at the end (
              <Term label="!" />
              ), and others don't. The exclamation mark signifies that a certain
              property is mandatory, and its value cannot be null. This makes
              properties without an exclamation mark optional.
            </>
          }
        />
      </p>

      <p>
        Each property has its own data type. There are a series of primitive
        data types (a primary data type is also called a{" "}
        <Notion label="scalar" />)
      </p>

      {scalarTypes}

      <p>
        Along with scalar types, each data type that we define can be used as
        the value of another data type, thus creating a hierarchy. In our
        example, the <Term label="Ingredient" /> type is used as the data type
        of the <Term label="ingredients" /> field of <Term label="Recipe" />.
        The square brackets which wrap it mean that this property contains a
        list of elements of type <Term label="Ingredient" />, not just a single
        one. This way, we can create an organised data structure, which allows
        us to represent any information.
      </p>

      <h3>Queries and mutations</h3>

      <p>
        After we have defined our data types, the next step is to define how we
        interact with our API. In GraphQL, this interaction happens through{" "}
        <Notion notionKey="query" label="queries" />
        and <Notion notionKey="mutation" label="mutations" />. These are ways to
        request information from the API or to ask the API to change data in the
        database on our behalf.
      </p>

      <p>
        For the data types we have defined, we need 2 queries: one to request
        all the recipes, and another one to request a single recipe. They look
        like this:
      </p>

      {code2}

      <p>
        Along with operations which allow us to request information from the API
        (queries), we also need a way to change data. First of all, we need a
        way to create a recipe. For this, we use a mutation:
      </p>

      {code3}

      <p>
        In the body of each operation we have defined a list of properties (
        <Term label="id" />, <Term label="name" />, <Term label="description" />
        , <Term label="photoUrl" />, <Term label="ingredients" />
        ). Each query and mutation has the capacity to return a series of
        properties for each element they return, and in this way we can specify
        what those properties are. They have to be chosen from the properties
        defined for that particular data type (in our example, that is{" "}
        <Term label="Recipe" />
        ).
      </p>

      <p>
        <Important label="Both queries and mutations can use variables." />
      </p>

      <p>
        For example, if we request a list of recipes (
        <Term label="ListRecipes" />
        ), we don't necessarily need to give the API any extra information, but
        if we want to request information about a particular recipe, then we
        need to specify the ID of the recipe we are looking for. We would
        accomplish this by using a variable.
      </p>

      <p>
        Then, if we want to create add a recipe to the database (
        <Term label="CreateRecipe" />
        ), we must communicate to the API the details of the recipe we are about
        to create.
      </p>

      <p>
        In the mutation from our example, called
        <Term label="CreateRecipe" />, the variable called{" "}
        <Term label="$input" /> has a data type associated to it, and that is{" "}
        <Term label="CreateRecipeInput" />. This data type also needs to be
        defined, and inside it we will define a property for each piece of
        information we need in order to create an element of type{" "}
        <Term label="Recipe" />. In our mutation, <Term label="$input" /> is
        marked as being mandatory because without it, we would not have any
        information about the recipe we want to create.
      </p>

      <p>
        To define <Term label="CreateRecipeInput" />, we can create a special
        data type, defined with the keyword <Term label="input" />, instead of{" "}
        <Term label="type" />. We can do it this way:
      </p>

      {code4}

      <p>
        Each property that we have made mandatory when we defined{" "}
        <Term label="Recipe" /> must also be mandatory in the definition of{" "}
        <Term label="CreateRecipeInput" />, otherwise we will receive an error
        after we create a recipe, when we try to retrieve it from the API.
      </p>

      <p>So far, our APIs entire schema looks like this:</p>

      {code5}

      <h3>Resolvers</h3>

      <p>
        Each query and mutation that we have defined in the schema must be
        backed by a function which creates the connection between the schema and
        the database. These functions are called{" "}
        <Notion label="resolvers" notionKey="resolver" />. Without resolvers,
        our API will not do anything. They are responsible for actually carrying
        out the operations we define in the schema: they fetch data from the
        database, manipulate it, create it or delete it. Depending on what tools
        we use to create our API, these functions must be created manually by
        us, the developers, or they can be generated automatically by the tool
        we use, based on the schema. In most cases, the operations are standard,
        and thus we can rely on an automated tool to generate our resolvers.
        There are also specific cases in which we do need to write these
        functions manually, but we won't cover that now.
      </p>
    </>
  );
}

const lesson = {
  abstract: <LESSON_EN_ABSTRACT />,
  page: <LESSON_EN_PAGE />,
};

export default lesson;
