import React from "react";
import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";

export const code1 = (
  <Code>{`for(var i:number = 0; i < 10; i++){

};
`}</Code>
);

export const code2 = <CodeEditor defaultCode={`for(var i:number = 0; i < 10; i++){\n  console.log(i);\n}`} />;
export const code3 = (
  <CodeEditor
    stacked
    defaultCode={`var letters:string[] = ["a", "b", "c", "d"];\n\nfor(var i:number = 0; i < 10; i++){\n  console.log(letters[i]);\n}`}
  />
);
