const notions = {
  en: {
    no_explanation:
      "This notion does not have an explanation in the English language yet.",
    graphql:
      "GraphQL is a query language for APIs and a runtime for fulfilling those queries with your existing data. GraphQL provides a complete and understandable description of the data in your API, gives clients the power to ask for exactly what they need and nothing more, makes it easier to evolve APIs over time, and enables powerful developer tools.",
    ui:
      "The user interface (UI), in the industrial design field of human-computer interaction, is the space where interactions between humans and machines occur. The goal of this interaction is to allow effective operation and control of the machine from the human end, whilst the machine simultaneously feeds back information that aids the operators' decision-making process",
    gui: "GUI (Graphical User Interface)",
    ui_kit: "",
    mysql: "",
    postgresql: "",
    mongodb: "",
    dynamodb: "",
    sdl: "Schema Definition Language",
    json: "JavaScript Object Notation",

    int: "",
    float: "",
    boolean: "",
    string: "",
    id: "",
    query: "",
    mutation: "",
    george_boole: "https://en.wikipedia.org/wiki/George_Boole",
    ascii: "https://www.ascii-code.com/",
    truthy:
      'A value is called "truthy"  when it is true or any other value that TypeScript doesn\'t consider false. The opposite of this is "falsey". That is all values which TypeScript does consider false: the value false itself, 0, an empty string (""), undefined, null and NaN',
  },
  ro: {
    no_explanation: "Aceasta notiune nu are inca o explicatie in limba romana.",
    graphql:
      "GraphQL este un limbaj folosit pentru a cere informatii de la API-uri si un runtime care permite aplicatiilor sa raspunda acestor cerinte cu informatiile tale deja existente. GraphQL ofera o descriere completa si usor de inteles a datelor din API-ul tau, da clientilor puterea sa ceara exact ceea ce au nevoie si nimic mai mult, face mai usoara evoluarea API-urilor in timp, si permite crearea unor unelte puternice pentru programatori.",
    ui:
      "UI (User Interface) Interfata vizuala, in campul design-ului industrial din care face parte interactiunea om-computer, este spatiul in care interactiunile dintre oameni si computere se intampla. Scopul acestor interactiuni este sa permita folosirea eficienta a computerului, in timp ce computerul serveste informatii care ajuta deciziile luate de operator.",
    gui: "GUI (Graphical User Interface)",
    ui_kit: "",
    mysql: "",
    postgresql: "",
    mongodb: "",
    dynamodb: "",
    sdl: "Schema Definition Language",
    json: "JavaScript Object Notation",
    george_boole: "https://ro.wikipedia.org/wiki/George_Boole",
    ascii: "https://www.ascii-code.com/",
    hoisting:
      "Mutarea automata a unor blocuri de cod la inceputul contextului curent, ceea ce le permite sa fie folosite inainte de a fi declarate. In functie de limbajul de programare, se poate aplica atat la functii, cat si la variabile.",
    "call stack": "",
    truthy:
      'O valoare se numeste "truthy" (din limba engleza, "truth"="adevar") atunci cand este true (adevarat) sau cand este orice alta valoare pe care limbajul TypeScript nu o considera falsa. Opusul acestui term este "falsey" (din limba engleza, "false" = "fals"), care reprezinta toate valorile pe care TypeScript le considera false: valoarea false, 0, string gol (""), undefined, null si NaN',
  },
};

export default notions;
