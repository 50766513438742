import React from "react";
import { Space } from "antd";
import translate from "../../common/translate";
import Exercise from "../../Exercise/Exercise";
// import Notion from "../../Notion/Notion";
// import Important from "../../Important/Important";

function LESSON_EN_ABSTRACT() {
  return (
    <p>
      Loops (also called repetitive structures) represent a way of executing the same code multiple times. <br />A
      simple example would be "perform X operation Y times".
    </p>
  );
}

function LESSON_EN_PAGE() {
  return (
    <>
      <LESSON_EN_ABSTRACT />

      <Space size={30}>
        <Exercise
          label={`${translate("Exercise")} 1`}
          defaultCode={``}
          requirement="Declare a variable called 'name', of type string and with a value of 'Mark'."
          expectedCode={["var name:string = 'Mark';"]}
        />
        <Exercise
          label={`${translate("Exercise")} 2`}
          defaultCode={``}
          expectedCode={[
            `var a:number = 3;
var b:number = 7;`,
          ]}
          requirement={
            <>
              <span>Declare 2 variables: </span>

              <ul>
                <li>1 variable called 'a', of type number and with a value of 3.</li>
                <li>1 variable called 'b', of type number and with a value of 7.</li>
              </ul>
            </>
          }
        />
      </Space>

      <br />
      <br />
      <Space>
        <Exercise
          label={`${translate("Exercise")} 5`}
          defaultCode={``}
          expectedCode={[
            `type Person = {
  name:string;
  age: number;
  favoriteColors: string[];
};

var me:Person = {
  name: "John",
  age: 30,
  favoriteColors: ["pink", "orange"]
}`,
          ]}
          requirement={
            <>
              <span>
                1. Declare a type called "Person", with the following properties:
                <ul>
                  <li>a property called "name" of type string</li>
                  <li>a property called "age" of type number </li>
                  <li>a property called "favoriteColors" of type array of strings</li>
                </ul>
                2. Declare a variable called "me" of type Person, which has the following properties:
              </span>
              <ul>
                <li>a property "name" with a value of "John"</li>
                <li>a property "age" with a value of 30</li>
                <li>a property "favoriteColors" containing the values "pink" and "orange"</li>
              </ul>
            </>
          }
        />
      </Space>

      <div>
        <br />
        <i>This lesson does not have an English version yet, please check out the Romanian version for now.</i>
      </div>
    </>
  );
}

const lesson = {
  abstract: <LESSON_EN_ABSTRACT />,
  page: <LESSON_EN_PAGE />,
};

export default lesson;
