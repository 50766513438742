import React from "react";
import { Button, Menu, Dropdown } from "antd";
import ReactCountryFlag from "react-country-flag";
import "./LanguagePicker.scss";

export default function LanguagePicker({ language, setLanguage }) {
  const languageMenu = (
    <Menu>
      <Menu.Item onClick={() => setLanguage("en")}>
        <ReactCountryFlag countryCode="GB" svg />
        English
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => setLanguage("ro")}>
        <ReactCountryFlag countryCode="RO" svg />
        Romana
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="language-picker">
      <Dropdown
        overlay={languageMenu}
        overlayClassName="language-picker-list"
        trigger={["click"]}
      >
        {language === "en" ? (
          <Button className="change-language-en">
            <ReactCountryFlag countryCode="GB" svg />
            English
          </Button>
        ) : (
          <Button className="change-language-ro">
            <ReactCountryFlag countryCode="RO" svg />
            Romana
          </Button>
        )}
      </Dropdown>
    </div>
  );
}
