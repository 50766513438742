import React from "react";
import { Space, Divider, Typography } from "antd";
// import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
import { code1, code2, code3, code4 } from "./common";
// import Code from "../../Code/Code";
import translate from "../../common/translate";

function LESSON_RO_ABSTRACT() {
  return (
    <p>
      "Variabila" suna ca un termen din matematica si pe de o parte este, dar variabilele in programare sunt folosite
      pentru mult mai mult decat numere. Ele ne permit sa stocam orice fel de informatie si sa o accesam cand avem
      nevoie.
    </p>
  );
}

function LESSON_RO_PAGE() {
  return (
    <>
      <LESSON_RO_ABSTRACT />

      <p>In TypeScript, o variabila este declarata cu unul din 3 cuvinte cheie: .</p>
      <p>
        Atunci cand creem o variabila, spunem ca o declaram. Pentru a declara o variabila in TypeScript, avem nevoie de
        urmatoarele elemente (scrise exact in aceasta ordine):
      </p>
      <ul>
        <li>
          cuvantul cheie pentru declarare, care trebuie sa fie unul din: <Term label="var" />, <Term label="let" /> sau{" "}
          <Term label="const" /> (vom elabora in detaliu diferentele dintre cele 3 cuvinte cheie, dar pentru moment vom
          folosi doar <Term label="var" />)
        </li>
        <li>
          nume: numele poate contine litere, numere si underscore ( <Term label="_" /> ), dar nu pot incepe cu un numar.
        </li>
        <li>
          semnul <Term label=":" />
        </li>
        <li>
          tip de date: acesta poate fi unul dintre tipurile esentiale invatate pana acum, sau orice alt tip definit de
          programator (vom avea o intreaga lectie despre cum ne putem defini propriile tipuri de date).
        </li>
        <li>
          semnul <Term label="=" />
        </li>
        <li>valoare: valoarea trebuie sa fie, evident, una care corespunde tipului de date ales</li>
      </ul>

      <br />
      <p>Cateva exemple de declarare a unei variabile:</p>
      {code1}
      <Important
        label={
          <>
            Putem observa ca dupa fiecare linie de cod avem semnul ; <br /> In TypeScript, semnul ; este pus pentru a
            marca finalul liniei de cod si este necesar in majoritatea situatiilor, desi exista anumite linii unde nu
            este necesar. (le vom descoperi pe parcurs)
          </>
        }
      />
      <br />
      <br />

      <Space size={30}>
        <Exercise
          label={`${translate("Exercise")} 1`}
          defaultCode={``}
          requirement="Declara o variabila cu numele 'name', tipul string si valoarea 'Mark'."
          expectedCode={["var name:string = 'Mark';"]}
        />
        <Exercise
          label={`${translate("Exercise")} 2`}
          defaultCode={``}
          expectedCode={[
            `var a:number = 3;
var b:number = 7;`,
          ]}
          requirement={
            <>
              <span>Declara 2 variabile: </span>
              <ul>
                <li>1 variabila cu numele 'a', tipul number si valoarea 3.</li>
                <li>1 variabila cu numele 'b', tipul number si valoarea 7.</li>
              </ul>
            </>
          }
        />
        <Exercise
          label={`${translate("Exercise")} 3`}
          defaultCode={`var products:string[] = [
  "milk", 
  "cheese", 
  "bread", 
  "tomatoes"
];`}
          expectedCode={[
            `var products:string[] = [
  "milk", 
  "cheese", 
  "bread", 
  "tomatoes"
];
var myProduct:string = products[2];`,
          ]}
          requirement={
            <>
              <span>
                Creeaza o variabila numita "myProduct" de tip string, a carei valoare sa fie al treilea element din
                array-ul "products".
              </span>
            </>
          }
        />
      </Space>
      <br />
      <br />
      <br />

      <Divider>
        <Typography.Title level={2}>Accesarea elementelor dintr-un array</Typography.Title>
      </Divider>
      <br />
      <p>
        Am invatat deja ca un array reprezinta o lista de elemente si ca fiecare element are asociata o pozitie (numita
        si index). Array-urile sunt foarte utile in primul rand pentru ca putem accesa orice element dintr-un array daca
        ii stim index-ul, astfel:
      </p>
      {code2}
      <p>
        In exemplul de mai sus, variabila numita "first" contine primul element al array-ului, iar variabila numita
        "second" contine al doilea element.
      </p>
      <p>
        Deja stim ca pentru a declara un array, folosim parantezele patrate, iar in interiorul lui specificam elementele
        array-ului. Pentru a accesa valoarea unui element dintr-un array, scriem numele array-ului (in cazul nostru este
        "letters"), urmat de un set de paranteze patrate, in care specificam index-ul elementului dorit.
      </p>
      <Important label="Index-ul primului element dintr-un array este intotdeauna zero." />
      <br />
      <br />
      <Divider>
        <Typography.Title level={2}>Definirea propriilor tipuri de date</Typography.Title>
      </Divider>
      <p>
        Atunci cand avem variabile de tip object, este de preferat sa definim un tip de date care sa descrie structura
        acelui obiect. Cu alte cuvinte, aste ne permite sa specificam nu numai ce proprietati are acel obiect, dar si
        tipul de date al fiecarei proprietati.
      </p>

      <Important
        label="Desi tipul pe care il declaram descrie un obiect, el insusi nu reprezinta un obiect, intrucat nu are valori. El doar specifica
          numele si tipul de date al proprietatilor respectivului obiect. Orice obiect care este de un anumit tip trebuie sa aiba toate proprietatile definite in acel tip, cu aceleasi tipuri de date."
      />

      <p></p>

      <p>Putem defini un tip de date in 2 moduri:</p>
      <ul>
        <li>
          Putem folosi cuvantul-cheie <Term label="type" />, care ne permite sa definim un tip de date in plus fata de
          cele pe care le ofera limbajul. Acest tip de date poate fi apoi asociat oricator variabila. Sintaxa este putin
          diferita de atunci cand definim un obiect, prin faptul ca fiecare linie de cod care defineste o proprietate nu
          se termina cu virgula, ci cu punct si virgula.
          {code3}
        </li>
        <li>
          Putem defini tipul "inline", adica definim un tip de date atunci cand definim si obiectul propriu-zis. Tipul
          de date creat astfel nu poate fi refolosit, pentru ca nu avem cum sa ne referim la el mai tarziu.
          {code4}
        </li>
      </ul>
      <br />

      <Space size={30}>
        <Exercise
          label={`${translate("Exercise")} 4`}
          defaultCode={``}
          expectedCode={[
            `var me: {
  name:string;
  age: number;
  favoriteColors: string[];
} = {
  name: "John",
  age: 30,
  favoriteColors: ["pink", "orange"]
}`,
          ]}
          requirement={
            <>
              <span>
                Folosind un tip declarat inline, creeaza o variabila numita "me" de tip object, care sa aiba 3
                proprietati:
              </span>
              <ul>
                <li>proprietatea "name" de tip string si valoarea "John"</li>
                <li>proprietatea "age" de tip number si valoarea 30</li>
                <li>
                  proprietatea "favoriteColors" de tip array de string-uri, care sa contina valorile "pink" si "orange"
                </li>
              </ul>
            </>
          }
        />

        <Exercise
          label={`${translate("Exercise")} 5`}
          defaultCode={``}
          expectedCode={[
            `type Person = {
  name:string;
  age: number;
  favoriteColors: string[];
};

var me:Person = {
  name: "John",
  age: 30,
  favoriteColors: ["pink", "orange"]
}`,
          ]}
          requirement={
            <>
              <span>
                1. Declara un tip numit "Person", cu proprietatile:
                <ul>
                  <li>proprietatea "name" de tip string</li>
                  <li>proprietatea "age" de tip number </li>
                  <li>proprietatea "favoriteColors" de tip array de string-uri</li>
                </ul>
                2. Declara un obiect numit "me" de tipul Person, care sa aiba urmatoarele proprietati:
              </span>
              <ul>
                <li>proprietatea "name" cu valoarea "John"</li>
                <li>proprietatea "age" cu valoarea 30</li>
                <li>proprietatea "favoriteColors" care sa contina valorile "pink" si "orange"</li>
              </ul>
            </>
          }
        />
      </Space>
    </>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
