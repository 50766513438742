import React from "react";
import cx from "classnames";

import notions from "./notions";

import "./Notion.scss";

function getExplanation(key) {
  const explanation = notions[window.language][key];
  if (!explanation.includes("refkey-")) {
    return explanation;
  } else {
    const referencedKey = explanation.substring(7);

    return getExplanation(referencedKey);
  }
}

export default function Notion({ label, notionKey, important }) {
  let explanation = null;
  let key = notionKey || label.toLowerCase().split(" ").join("_");
  try {
    explanation = getExplanation(key);
    if (explanation?.includes("http")) {
      explanation = (
        <a href={explanation} target="_blank" rel="noopener noreferrer">
          {explanation}
        </a>
      );
    }
  } catch (e) {}

  if (!explanation) {
    explanation = notions[window.language || "en"].no_explanation;
  }
  return (
    <span className={cx("notion", { "is-important": important })}>
      <span className="notion-label">{label}</span>
      <span className="explanation">{explanation}</span>
    </span>
  );
}
