import Intro from "./LessonIntro/versions";
import DataTypes from "./LessonDataTypes/versions";
import Variables from "./LessonVariables/versions";
import Operators from "./LessonOperators/versions";
import Console from "./LessonConsole/versions";
import Comments from "./LessonComments/versions";
import Loops1 from "./LessonLoops1/versions";
import Conditions from "./LessonConditions/versions";
import Functions from "./LessonFunctions/versions";
import Strings from "./LessonStrings/versions";
import Arrays from "./LessonArrays/versions";
import ExercisesArrayMethods from "./LessonExercisesArrayMethods/versions";

import GraphQL from "./LessonGraphQL/versions";
// import AntDesign from "./LessonAntDesign/versions";

const lessonList = [
  {
    title: "Intro",
    slug: "intro",
    content: Intro,
  },
  {
    title: "Data Types",
    slug: "data-types",
    content: DataTypes,
  },
  {
    title: "Variables",
    slug: "variables",
    content: Variables,
  },
  {
    title: "Operators",
    slug: "operators",
    content: Operators,
  },
  {
    title: "Comments",
    slug: "comments",
    content: Comments,
  },
  {
    title: "Console",
    slug: "console",
    content: Console,
  },
  {
    title: "Loops1",
    slug: "loops-1",
    content: Loops1,
  },
  {
    title: "Conditions",
    slug: "conditions",
    content: Conditions,
  },
  {
    title: "Functions",
    slug: "functions",
    content: Functions,
  },
  {
    title: "String",
    slug: "string",
    content: Strings,
  },
  {
    title: "Array",
    slug: "arrays",
    content: Arrays,
  },
  {
    title: "Exercises Array Methods",
    slug: "exercises-array-methods",
    content: ExercisesArrayMethods,
  },
  {
    title: "GraphQL",
    slug: "graphql",
    content: GraphQL,
    index: 50,
    displayDividerBefore: true,
  },
  // {
  //   title: "Ant Design",
  //   slug: "antdesign",
  //   content: AntDesign,
  //   index: 51,
  // },
];

export default lessonList;
