import React from "react";
import { Divider, Typography, Row, Col, Space } from "antd";
import Notion from "../../Notion/Notion";
import Term from "../../Term/Term";
import Important from "../../Important/Important";
import Exercise from "../../Exercise/Exercise";
// import * as common from "./common";
import Code from "../../Code/Code";
import CodeEditor from "../../CodeEditor/CodeEditor";
import translate from "../../common/translate";

import "./common.scss";

function LESSON_RO_ABSTRACT() {
  return <p></p>;
}

function LESSON_RO_PAGE() {
  return (
    <div className="lesson-functions">
      <LESSON_RO_ABSTRACT />
      <Space size={30}>
        <Exercise
          stacked={true}
          label={`${translate("Exercise")} 1`}
          defaultCode={``}
          requirement="Adauga elementul 'Scott' la lista de nume"
          defaultCode={`var names:string[] = ['John', 'Elizabeth', 'Archie'];`}
          expectedCode={[
            `var names:string[] = ['John', 'Elizabeth', 'Archie'];
names.push('Scott');`,
          ]}
        />
        <Exercise
          stacked={true}
          label={`${translate("Exercise")} 2`}
          requirement={
            <>
              <span>
                Creeaza un nou array care sa contina doar produsele cu pretul
                mai mic de 30. Afiseaza rezultatul in consola.
              </span>
            </>
          }
          defaultCode={`var products:Array = [
  {name: "Tea", price: 20}, 
  {name: "Coffee", price: 10}, 
  {name: "Milk", price: 50}, 
  {name: "Strawberries", price: 40}
];
`}
          expectedLog={`[{"name":"Tea","price":20},{"name":"Coffee","price":10}]`}
          solution={[
            `var products:Array = [
  {name: "Tea", price: 20}, 
  {name: "Coffee", price: 10}, 
  {name: "Milk", price: 50}, 
  {name: "Strawberries", price: 40}
];

var result = products.filter(product: Object => product.price < 30);

console.log(JSON.stringify(result));
`,
          ]}
        />

        <Exercise
          stacked={true}
          label={`${translate("Exercise")} 3`}
          requirement={
            <>
              <span>Ordoneaza produsele dupa pret, crescator.</span>
            </>
          }
          defaultCode={`var products:Array = [
  {name: "Tea", price: 20}, 
  {name: "Coffee", price: 10}, 
  {name: "Milk", price: 50}, 
  {name: "Strawberries", price: 40}
];
`}
          expectedLog={`[{"name":"Coffee","price":10},{"name":"Tea","price":20},{"name":"Strawberries","price":40},{"name":"Milk","price":50}]`}
          solution={[
            `var products:Array = [
  {name: "Tea", price: 20}, 
  {name: "Coffee", price: 10}, 
  {name: "Milk", price: 50}, 
  {name: "Strawberries", price: 40}
];
            
var result = products.sort((a: Object, b: Object) => {
  if(a.price < b.price) {
    return -1;
  } else {
    return 1;
  }
});

console.log(JSON.stringify(result));
            `,
            `var products:Array = [
  {name: "Tea", price: 20}, 
  {name: "Coffee", price: 10}, 
  {name: "Milk", price: 50}, 
  {name: "Strawberries", price: 40}
];

var result = products.sort((a: Object, b: Object) => a.price - b.price);

console.log(JSON.stringify(result));
`,
          ]}
        />

        <Exercise
          stacked={true}
          label={`${translate("Exercise")} 4`}
          requirement={
            <>
              <span>
                Creeaza un nou array care sa contina doar produsele din
                categoria "tools". Din acest array, afiseaza in consola un nou
                array care contine doar numele fiecarui produs.
              </span>
            </>
          }
          defaultCode={`var products:Array = [
  {name: "Tea", price: 20, category: "food"}, 
  {name: "Coffee", price: 10, category: "food"}, 
  {name: "Milk", price: 50, category: "food"}, 
  {name: "Strawberries", price: 40, category: "food"},
  {name: "Knife", price: 100, category: "tools"},
  {name: "Hammer", price: 80, category: "tools"},
];
`}
          expectedLog={[`["Knife","Hammer"]`, `Knife,Hammer`]}
          solution={[
            `var products:Array = [
  {name: "Tea", price: 20, category: "food"}, 
  {name: "Coffee", price: 10, category: "food"}, 
  {name: "Milk", price: 50, category: "food"}, 
  {name: "Strawberries", price: 40, category: "food"},
  {name: "Knife", price: 100, category: "tools"},
  {name: "Hammer", price: 80, category: "tools"},
];

var result = products
    .filter((product: Object) => product.category === "tools")
    .map((product: Object) => product.name);

console.log(result);
`,
          ]}
        />

        <Exercise
          stacked={true}
          label={`${translate("Exercise")} 5`}
          requirement={
            <>
              <span>
                Adauga 2 elemente noi la inceputul array-ului: "Juice" si
                "Cheese".
                <br /> Ordoneaza elementele alfabetic, apoi afiseaza in consola
                un array format doar din prima litera a fiecarui element.
              </span>
            </>
          }
          defaultCode={`var products: string[] = [
  "Tea",
  "Coffee",
  "Milk",
  "Strawberries",
  "Knife",
  "Hammer",
];
`}
          expectedLog={`C,C,H,J,K,M,S,T`}
          solution={[
            `var products: string[] = [
  "Tea",
  "Coffee",
  "Milk",
  "Strawberries",
  "Knife",
  "Hammer",
];

products.unshift("Juice", "Cheese");

var result: string[] = products.sort().map((product: string) => product[0]);

console.log(result);
`,
            `var products:string[] = [
  "Tea",
  "Coffee",
  "Milk",
  "Strawberries",
  "Knife",
  "Hammer",
];

products.splice(0, 0, "Juice", "Cheese");

var result = products.sort((a: string, b: string) => {
  if(a < b) {
    return -1;
  } else {
    return 1;
  }
}).map((product: string) => product[0]);

console.log(result);
`,
          ]}
        />

        <Exercise
          stacked={true}
          label={`${translate("Exercise")} 6`}
          requirement={
            <>
              <span>
                Gaseste elementul cu numele "Milk" si afiseaza-i pretul in
                consola.
              </span>
            </>
          }
          defaultCode={`var products:Array = [
  {name: "Tea", price: 20, category: "food"}, 
  {name: "Coffee", price: 10, category: "food"}, 
  {name: "Milk", price: 50, category: "food"}, 
  {name: "Strawberries", price: 40, category: "food"},
  {name: "Knife", price: 100, category: "tools"},
  {name: "Hammer", price: 80, category: "tools"},
];
`}
          expectedLog={`50`}
          solution={[
            `var products:Array = [
  {name: "Tea", price: 20, category: "food"}, 
  {name: "Coffee", price: 10, category: "food"}, 
  {name: "Milk", price: 50, category: "food"}, 
  {name: "Strawberries", price: 40, category: "food"},
  {name: "Knife", price: 100, category: "tools"},
  {name: "Hammer", price: 80, category: "tools"},
];

var product = products.find((product: Object) => product.name === "Milk");

console.log(product.price);
`,
          ]}
        />

        <Exercise
          stacked={true}
          label={`${translate("Exercise")} 7`}
          requirement={
            <>
              <span>
                Calculeaza suma preturilor tuturor elementelor din categoria
                "food" si afiseaz-o in consola.
              </span>
            </>
          }
          defaultCode={`var products:Array = [
  {name: "Tea", price: 20, category: "food"}, 
  {name: "Coffee", price: 10, category: "food"}, 
  {name: "Milk", price: 50, category: "food"}, 
  {name: "Strawberries", price: 40, category: "food"},
  {name: "Knife", price: 100, category: "tools"},
  {name: "Hammer", price: 80, category: "tools"},
];
`}
          expectedLog={`120`}
          solution={[
            `var products:Array = [
  {name: "Tea", price: 20, category: "food"}, 
  {name: "Coffee", price: 10, category: "food"}, 
  {name: "Milk", price: 50, category: "food"}, 
  {name: "Strawberries", price: 40, category: "food"},
  {name: "Knife", price: 100, category: "tools"},
  {name: "Hammer", price: 80, category: "tools"},
];

var sum = 0;

products.forEach((product: Object) => {
  if(product.category === "food") {
    sum += product.price;
  }
})

console.log(sum);
`,

            `var products:Array = [
  {name: "Tea", price: 20, category: "food"}, 
  {name: "Coffee", price: 10, category: "food"}, 
  {name: "Milk", price: 50, category: "food"}, 
  {name: "Strawberries", price: 40, category: "food"},
  {name: "Knife", price: 100, category: "tools"},
  {name: "Hammer", price: 80, category: "tools"},
];


var foodProducts = products;
var sum = products
  .filter((product: Object) => product.category === 'food')
  .reduce((sum: Number, product: Object) => sum + product.price, 0);

console.log(sum);
`,
          ]}
        />
      </Space>
    </div>
  );
}

const lesson = {
  abstract: <LESSON_RO_ABSTRACT />,
  page: <LESSON_RO_PAGE />,
};

export default lesson;
